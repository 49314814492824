import React, { useEffect, useState } from 'react';
import { Participant } from '../../../interfaces';
import Card from '../../bootstrap/card';
import { Form, Button, Spinner } from 'react-bootstrap';
import { ApiClient } from '../../../services/ApiClient';
import { useToast } from '../../../services/context/ToastContext';

interface ParticipantImportantInfosProps {
    isLoading?: boolean;
    participant?: Participant | null;
}

const ParticipantImportantInfos: React.FC<ParticipantImportantInfosProps> = ({ participant }) => {
    const { showToast } = useToast();
    const [isFocused, setIsFocused] = useState(false);
    const [notes, setNotes] = useState('');
    const [initialNotes, setInitialNotes] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (participant) {
            const notice = participant.notice || '';
            setNotes(notice);
            setInitialNotes(notice);
        }
    }, [participant]);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => {
        if (!notes || notes === initialNotes) {
            setIsFocused(false);
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
    };

    const handleSubmit = async () => {
        if (!participant) return;

        setIsSubmitting(true);

        try {
            await ApiClient.put(`/participants/${participant.participants_id}`, { ...participant, notice: notes });
            showToast('Erfolgreich gespeichert', false);
            setInitialNotes(notes);
        } catch (error) {
            showToast('Fehler beim Speichern', true);
        } finally {
            setIsSubmitting(false);
            setIsFocused(false);
        }
    };

    return (
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                    <h4>Wichtige Informationen</h4>
                    {isFocused && notes !== initialNotes && (
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Spinner as="span" animation="border" size="sm" /> : 'Fertig'}
                        </Button>
                    )}
                </div>
                <hr />
                <Form>
                    <Form.Group controlId="importantInfoTextarea">
                        <Form.Control
                            className='bg-grey text-black'
                            as="textarea"
                            rows={6}
                            placeholder="Notiz hinzufügen"
                            value={notes}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default ParticipantImportantInfos;
