import React from 'react';
import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface ProgressBarComponentProps {
    now: number;
    label?: string;
    variant?: 'success' | 'info' | 'warning' | 'danger';
}

const ProgressBarComponent: React.FC<ProgressBarComponentProps> = ({ now, label, variant = 'info' }) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${variant}`}>
                    {now}%
                </Tooltip>
            }
        >
            <div className='py-2'>
                <ProgressBar style={{ height: '3px' }} now={now} label={label} variant={variant} />
            </div>
        </OverlayTrigger>
    );
}

export default ProgressBarComponent;
