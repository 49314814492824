import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { Product } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import ProductDetails from '../../components/products/detail/ProductDetails';

function ProductDetail() {
  const { productId } = useParams();
  const [product, setProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const { show, message, error, showToast, hideToast } = useToast();

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewProducts, 'read');

      if (hasPermission) {
        productId && fetchProduct(productId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [productId, permissionsLoaded]);

  const fetchProduct = async (productId: string) => {
    setIsLoading(true);
    try {
      // const response = await ApiClient.get(`/products/${productId}`);
      // const productResponse = response.data;

      //setProduct(productResponse);
      setProduct({
        products_id: 1,
        name: "Online Marketing SEO & SEA",
        title: "Online Marketing SEO & SEA",
        type: "combinations",
        status: "publish",
        slug: "online-marketing-seo-sea",
        lwId: "online-marketing-seo-sea",
        lwLink: "https://journey.onecareer.de/author/course?courseid=online-marketing-seo-sea",
        newLwSlug: "",
        newLwId: "",
        newLwLink: "",
        durationUes: 40,
        durationDays: 14,
        durationWeeks: 2
      })
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = () => {
    setProduct(undefined);
    productId && fetchProduct(productId);
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <Card className="card-block card-stretch card-height">
            <Card.Body>
              <ProductDetails product={product} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></ProductDetails>
            </Card.Body>
          </Card></Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default ProductDetail;
