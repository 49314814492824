import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import DropdownPortalMenu from './DropdownPortalMenu';
import { AttendanceStatusEnum, AttendanceStatus } from '../utils/enum';

interface AttendanceStatusDropdownProps {
    status: AttendanceStatus;
    handleStatusUpdate: (status: AttendanceStatus) => void;
    isDisabled?: boolean;
}

const getIconByAttendanceStatus = (status: AttendanceStatus) => {
    switch (status) {
        case 0:
            return <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />;
        case 1:
            return <FontAwesomeIcon icon={faCheckCircle} className="me-2" />;
        default:
            return null;
    }
};

const getAttendanceStatusColor = (status: AttendanceStatus) => {
    switch (status) {
        case 0:
            return 'text-danger';
        case 1:
            return 'text-success';
        default:
            return '';
    }
};

const AttendanceStatusDropdown: React.FC<AttendanceStatusDropdownProps> = ({
    status,
    handleStatusUpdate,
    isDisabled
}) => {
    return (
        <Dropdown>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="link"
                id="dropdown-attendance-status"
                className={`${getAttendanceStatusColor(status)} ps-0`}
            >
                {getIconByAttendanceStatus(status)}
                {AttendanceStatusEnum[status]}
            </Dropdown.Toggle>
            <DropdownPortalMenu>
                <Dropdown.Menu>
                    {Object.entries(AttendanceStatusEnum)
                        .filter(([statusKey, _]) => Number(statusKey) !== status)
                        .map(([statusKey, statusValue]) => (
                            <Dropdown.Item
                                key={statusKey}
                                onClick={() =>
                                    handleStatusUpdate(Number(statusKey) as AttendanceStatus)
                                }
                            >
                                {getIconByAttendanceStatus(Number(statusKey) as AttendanceStatus)}
                                {statusValue}
                            </Dropdown.Item>
                        ))}
                </Dropdown.Menu>
            </DropdownPortalMenu>
        </Dropdown>
    );
};

export default AttendanceStatusDropdown;
