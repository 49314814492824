import React from 'react';

// default layout
import Default from '../layouts/dashboard/default';
import ProtectedRoute from './ProtectedRoute';

// Dashboard
import Index from '../views/dashboard/index';
import Participants from '../views/participants/participants';
import ParticipantDetail from '../views/participants/participants-detail';
import Products from '../views/products/products';
import ProductDetail from '../views/products/products-detail';
import Deals from '../views/deals/deals';
import DealDetail from '../views/deals/deal-detail';
import Settings from '../views/settings/settings';
import SettingDetail from '../views/settings/setting-detail';
import Roles from '../views/roles/roles';
import RoleDetail from '../views/roles/roles-detail';
import Users from '../views/users/users';
import Webhooks from '../views/webhooks/webhooks';
import WebhookDetail from '../views/webhooks/webhook-detail';
import ApiKeys from '../views/apikeys/apikeys';
import ApiKeyDetail from '../views/apikeys/apikey-detail';
import Media from '../views/media/media';
import Satisfactions from '../views/satisfactions/satisfactions';
import SatisfactionDetail from '../views/satisfactions/satisfaction-detail';
import Activities from '../views/activities/acitivities';
import ActivityDetail from '../views/activities/activity-detail';
import Attendances from '../views/attendances/attendances'
import AttendanceDetail from '../views/attendances/attendance-detail';
import { Navigate } from 'react-router-dom';

export const DefaultRouter = [
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: <Navigate to="/oc" />,
      },
      {
        path: '/:companyId',
        element: <Default />,
        children: [
          {
            path: '/:companyId/',
            element: <Index />,
          },
          {
            path: '/:companyId/participants',
            element: <Participants />,
          },
          {
            path: '/:companyId/participant/:participantId',
            element: <ParticipantDetail />,
          },
          {
            path: '/:companyId/roles',
            element: <Roles />,
          },
          {
            path: '/:companyId/roles/:roleId',
            element: <RoleDetail />,
          },
          {
            path: '/:companyId/settings',
            element: <Settings />,
          },
          {
            path: '/:companyId/settings/:settingId',
            element: <SettingDetail />,
          },
          {
            path: '/:companyId/users',
            element: <Users />,
          },
          {
            path: '/:companyId/deals',
            element: <Deals />,
          },
          {
            path: '/:companyId/deals/:dealId',
            element: <DealDetail />,
          },
          {
            path: '/:companyId/products',
            element: <Products />,
          },
          {
            path: '/:companyId/product/:productId',
            element: <ProductDetail />,
          },
          {
            path: '/:companyId/webhooks',
            element: <Webhooks />,
          },
          {
            path: '/:companyId/webhooks/:webhookId',
            element: <WebhookDetail />,
          },
          {
            path: '/:companyId/apikeys',
            element: <ApiKeys />,
          },
          {
            path: '/:companyId/apikeys/:apikeyId',
            element: <ApiKeyDetail />,
          },
          {
            path: '/:companyId/media/:folderId?/:mediumId?',
            element: <Media />,
          },
          {
            path: '/:companyId/satisfactions/:satisfactionId',
            element: <SatisfactionDetail />,
          },
          {
            path: '/:companyId/satisfactions',
            element: <Satisfactions />,
          },
          {
            path: '/:companyId/activities/:activityId',
            element: <ActivityDetail />,
          },
          {
            path: '/:companyId/activities',
            element: <Activities />,
          },
          {
            path: '/:companyId/attendances/:attendanceId',
            element: <AttendanceDetail />,
          },
          {
            path: '/:companyId/attendances',
            element: <Attendances />,
          },
        ],
      },
    ],
  },
];
