import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../services/ApiClient';
import { Editor } from '@tinymce/tinymce-react';
import NotificationToast from './NotificationToast';
import { useToast } from '../services/context/ToastContext';
import { SelectOption } from './form/SelectWithSearch';
import { getEnumValue, PermissionsEnum, TimeModel, } from '../utils/enum';
import ComboButtonGroup, { ComboButtonId } from './ComboButtonGroup';
import { usePermissions } from '../hooks/usePermissions';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Deal, IGlobalMeasureDetails, Participant } from '../interfaces';
import GenericInfosCard from './GenericInfosCard';
import { formatEuro, formatDate } from '../utils/utils';
import Card from './bootstrap/card';

type GenerateWvModalProps = {
    modalTitle: string;
    buttonName: string;
    deal?: Deal
    participant?: Participant
    onSubmitSuccess: (id: number) => void;
    emailTemplateSettings?: { title: string, body: string }
    globalMeasureDetails?: IGlobalMeasureDetails
};

interface FormValues {
    measureDesignation: string
    contractLocation: string
    date: string
    title: string
    body: string
    participantId: number
    dealId: number
}

const comboButtons = [
    { id: 'general', label: 'Allgemeines' },
    { id: 'email', label: 'E-Mail' },
];

const GenerateWvModal: React.FC<
    GenerateWvModalProps
> = ({ modalTitle, buttonName, deal, participant, onSubmitSuccess, emailTemplateSettings, globalMeasureDetails }) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
    const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>(
        'general'
    );
    const { userHasPermissionByRight } = usePermissions();
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

    const [formValues, setFormValues] = useState<FormValues>({
        measureDesignation: deal && globalMeasureDetails ? globalMeasureDetails[deal.actionNumber].designation : '',
        contractLocation: deal?.contractLocation ?? '',
        date: deal?.date ?? new Date().toISOString().split('T')[0],
        title: emailTemplateSettings?.title ?? '',
        body: emailTemplateSettings?.body ?? '',
        participantId: participant?.participants_id ?? 0,
        dealId: deal?.deals_id ?? 0
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    useEffect(() => {
        if (!showModal) {
            setFormValues(initialFormValues as any);
        }
    }, [showModal]);

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                let response;
                console.log('Data for WV Generation')
                console.log(transformFormValues(formValues))
                // if (deal?.deals_id) {
                //   response = await ApiClient.put(`/deals/${deal?.deals_id}`, formValues);
                // } else {
                //   response = await ApiClient.post('/deals', formValues);
                // }
                // const dealsId = response.data.deals_id;
                // onSubmitSuccess(dealsId);
                // setFormValues(response.data);
                // setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                handleClose();
                setValidated(false);
            }
        }
    };

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value, type } = e.target;

        setFormValues((prev: any) => ({
            ...prev,
            [id]: type === 'number' ? Number(value) : value
        }));
    };

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        infoLabel: string = '',
        disabled = false
    ) => {

        const formatDate = (dateStr: string = "") => {
            if (type === 'date') {
                return dateStr.split(' ')[0];
            }
            return dateStr;
        };

        return (
            <Form.Group className="mb-3 w-100" controlId={id.toString()}>
                <Form.Label style={{ color: 'black' }}>
                    {label} {infoLabel && <small>{infoLabel}</small>}
                </Form.Label>
                {type === 'textarea' ? (
                    <Form.Control
                        as="textarea"
                        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
                        value={formValues[id] || ''}
                        onChange={handleInputChange}
                        required={required}
                        isInvalid={validated && !formValues[id]}
                        rows={6}
                        disabled={disabled}
                    />
                ) : (
                    <Form.Control
                        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
                        type={type}
                        value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]) : ''}
                        onChange={handleInputChange}
                        required={required}
                        isInvalid={validated && !formValues[id]}
                        disabled={disabled}
                    />
                )}
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
        );
    };

    const createSelectGroup = (
        id: keyof FormValues,
        label: string,
        options: SelectOption[],
        placeholder?: string,
        required = false
    ) => (
        <Form.Group
            style={{ color: 'black' }}
            className="mb-3 w-100"
            controlId={id}
        >
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Select
                value={formValues[id]}
                onChange={handleInputChange}
                required={required}
                style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );

    const createEditorGroup = (
        id: keyof FormValues,
        label: string,
        initialValue: string
    ) => (
        <Form.Group className="mb-3 w-100" controlId={id}>
            <Form.Label style={{ color: 'black' }}>{label}</Form.Label>
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                initialValue={initialValue}
                init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                        'advlist',
                        'autolink',
                        'lists',
                        'link',
                        'image',
                        'charmap',
                        'preview',
                        'anchor',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'insertdatetime',
                        'media',
                        'table',
                        'code',
                        'help',
                        'wordcount',
                    ],
                    toolbar: 'undo redo | bold italic underline forecolor | alignleft aligncenter alignright alignjustify'
                }}
                onEditorChange={(content) => {
                    setFormValues((prev: any) => ({
                        ...prev,
                        [id]: content,
                    }));
                }}
            />
        </Form.Group>
    );

    function transformFormValues(values: FormValues): FormValues {
        const transformedValues = { ...values };
        if (transformedValues.date) {
            transformedValues.date = formatDate(transformedValues.date, 'd.m.Y');
        }

        return transformedValues;
    }

    return (
        <>
            <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write')} className={`btn-soft-primary me-5`} onClick={handleShow}>
                {buttonName}
            </Button>

            <Modal
                centered
                size="xl"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen='xl-down'
            >
                {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={handleClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Erstellen
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col>
                            <ComboButtonGroup
                                buttons={comboButtons}
                                selectedCombo={selectedCombo}
                                setSelectedCombo={setSelectedCombo}
                                borderRadius="normal"
                            ></ComboButtonGroup>
                        </Col>
                    </Row>

                    <div className="horizontal-line my-3"></div>
                    <Form noValidate validated={validated} onSubmit={submit}>

                        <div
                            style={{
                                display:
                                    selectedCombo === 'general' ? 'block' : 'none',
                            }}
                        >
                            <>
                                <Row>
                                    <Col>
                                        <GenericInfosCard
                                            title=""
                                            infos={[
                                                {
                                                    label: 'Vorname',
                                                    value: participant ? (participant.firstname + (participant.secondName ? ' ' + participant.secondName : '')) : '-',
                                                },
                                                {
                                                    label: 'Nachname',
                                                    value: participant ? participant.lastname : '-',
                                                },
                                                {
                                                    label: 'BGS-Nummer',
                                                    value: deal ? deal.bgsnumber : '-',
                                                },
                                                {
                                                    label: 'Straße',
                                                    value: participant ? participant.street : '-',
                                                },
                                                {
                                                    label: 'Wohnort',
                                                    value: participant ? (participant.zipCode && participant.city ? participant.zipCode + ' ' + participant.city : '-') : '-',
                                                },
                                                {
                                                    label: 'Angebotsdatum',
                                                    value: deal ? formatDate(deal.offerDate, 'd.m.Y') : '-',
                                                },
                                                {
                                                    label: 'Maßnahmennummer',
                                                    value: deal ? deal.actionNumber : '-',
                                                },
                                            ]
                                            }
                                        ></GenericInfosCard>
                                    </Col>

                                    <Col>
                                        <GenericInfosCard
                                            title=""
                                            infos={[
                                                {
                                                    label: 'Startdatum, offiziell',
                                                    value: deal ? formatDate(deal.start, 'd.m.Y') : '-',
                                                },
                                                {
                                                    label: 'Enddatum, offiziell',
                                                    value: deal ? formatDate(deal.end, 'd.m.Y') : '-',
                                                },
                                                {
                                                    label: 'Arbeitszeitmodell',
                                                    value: getEnumValue(TimeModel, deal?.timeModel),
                                                },
                                                {
                                                    label: 'Unterrichtszeit in Wochen',
                                                    value: deal ? deal.durationWeeks : '-',
                                                },
                                                {
                                                    label: 'Unterrichtseinheiten, gesamt',
                                                    value: deal ? deal.ue : '-',
                                                },
                                                {
                                                    label: 'Maßnahmenkosten (Brutto)',
                                                    value: deal ? formatEuro(deal.priceTotalGross) : '-',
                                                },
                                            ]
                                            }
                                        ></GenericInfosCard>
                                    </Col>
                                </Row>

                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                {createFormGroup('measureDesignation', 'Maßnahmenbezeichnung', 'text')}
                                            </Col>
                                            <Col>
                                                {createFormGroup('contractLocation', 'Maßnahmenort', 'text')}
                                            </Col>
                                            <Col>
                                                {createFormGroup('date', 'Aktuelles Datum', 'date')}
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>

                            </>
                        </div>
                        <div
                            style={{
                                display:
                                    selectedCombo === 'email' ? 'block' : 'none',
                            }}
                        >
                            <Row>
                                <Col >
                                    {createFormGroup('title', 'Betreff', 'text')}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {createEditorGroup(
                                        'body',
                                        'Nachricht',
                                        emailTemplateSettings?.body ?? ''
                                    )}
                                </Col>
                            </Row>
                        </div>


                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default GenerateWvModal;
