import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Row,
  Col,
  Table,
  Form,
  Button,
} from 'react-bootstrap';
import {
  BgsStatus,
  PermissionsEnum,
  WvStatus,
} from '../../utils/enum';

import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../hooks/useSortableData';

import DynamicPagination from '../../components/table/DynamicPagination';
import { useFilters } from '../../hooks/participants/useFilters';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';

import SelectionActions from '../../components/participants/SelectionActions';
import { useSelection } from '../../hooks/useSelection';
import AddEditParticipantModal from '../../components/participants/modal/AddEditParticipantModal';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import { Participant } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import BgsStatusDropdown from '../../components/BgsStatusDropdown';
import WvStatusDropdown from '../../components/WvStatusDropdown';
import SearchInput from '../../components/SearchInput';

interface ParticipantsResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Participant[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
  { id: 'pending', label: 'Ausstehend' },
  { id: 'ready', label: 'Fertig' },
];

const comboButtonsSearchFilter = [
  { id: 'all', label: 'Alle' },
  { id: 'title', label: 'Titel' },
  { id: 'descriptionLong', label: 'Bildungsinhalte' },
  { id: 'keywords', label: 'Keywords' },
  { id: 'bdksLabel', label: 'BDKS', },
  { id: 'systematicsLabel', label: 'Systematik' },
];

const Participants = memo(() => {
  const { companyId = 'oc' } = useParams();
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [pageSize, setPageSize] = useState<number>(25);
  const [selectedComboSearchFilter, setSelectedComboSearchFilter] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { items: sortedParticipants, requestSort } = useSortableData(participants);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const { show, message, error, showToast, hideToast } = useToast();

  const getId = useCallback((participant: Participant) => participant.participants_id!, []);

  const {
    selectedItems: selectedParticipants,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(participants, getId);

  const {
    selectedCombo,
    setSelectedCombo,
    handleCertificationStatusChange,
    handlePublicationStatusChange,
    handleSelectedCombo,
    selectedCertificationStatusFilter,
    selectedPublicationStatusFilter,
    refetchTrigger
  } = useFilters();


  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    }
  };

  const fetchParticipants = useCallback(async () => {
    setParticipants([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&comboStatus=${selectedCombo}`;
    } else {
      if (selectedCertificationStatusFilter?.toString()) {
        queryParams += `&statusCertification=${selectedCertificationStatusFilter}`;
      }
      if (selectedPublicationStatusFilter?.toString()) {
        queryParams += `&status=${selectedPublicationStatusFilter}`;
      }

      if (searchQuery) {
        if (searchQuery) {
          queryParams += `&search=${encodeURIComponent(searchQuery)}`;
          if (selectedSearchFilter !== 'all') {
            queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
          }
        }
      }
    }

    try {
      const response = await ApiClient.get(`/participants${queryParams}`);
      const participantsResponse = response.data as ParticipantsResponse;
      setTotalPages(participantsResponse.amountPages);
      setParticipants(participantsResponse.list);
      setCurrentPage(participantsResponse.page);
      setPageSize(participantsResponse.itemsPerPage);
      setTotalEntries(participantsResponse.amountAllItems);
      setAvailableFilter(participantsResponse.searchFilters)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [
    currentPage,
    selectedCombo,
    selectedCertificationStatusFilter,
    selectedPublicationStatusFilter,
    searchQuery,
    selectedComboSearchFilter
  ]);

  const handleBgsStatusUpdate = async (
    participant: Participant,
    newBgsStatus: BgsStatus
  ) => {

    const dealId = "1"

    const currentDeal = {}

    try {
      await ApiClient.put(`/deals/${dealId}`, {
        bgsStatus: newBgsStatus,
      });

      await fetchParticipants();

      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  };

  const handleWvStatusUpdate = async (
    participant: Participant,
    newWvStatus: WvStatus
  ) => {
    const dealId = "1"

    const currentDeal = {}

    try {
      await ApiClient.put(`/deals/${dealId}`, {
        wvStatus: newWvStatus,
      });

      await fetchParticipants();

      showToast('Erfolgreich gespeichert', false);
    } catch (error: any) {
      showToast('Fehler beim Speichern', true);
    }
  };

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read');

      if (hasPermission) {
        fetchParticipants();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    selectedCertificationStatusFilter,
    selectedPublicationStatusFilter,
    currentPage,
    searchQuery,
    selectedCombo,
    fetchParticipants,
    permissionsLoaded,
    location,
    refetchTrigger
  ]);

  const handleModalSubmit = (participantId: number) => {
    navigate(`/${companyId}/participant/${participantId}`);
  };

  const handleParticipantUpdateSubmit = () => {
    fetchParticipants();
  };

  const handleComboSearchFilterSelect = (newCombo: ComboButtonId) => {
    setSelectedComboSearchFilter(newCombo);
    setCurrentPage(1);
  };

  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  useEffect(() => {
    resetSearch();
  }, [location]);


  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Teilnehmer</h3>
        <div className="d-flex">
          <AddEditParticipantModal
            modalTitle="Teilnehmer hinzufügen"
            buttonName="Teilnehmer hinzufügen"
            onSubmitSuccess={handleModalSubmit}
          ></AddEditParticipantModal>
        </div>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>

              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={setSelectedCombo}
                  borderRadius="normal"
                ></ComboButtonGroup>}

            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('name')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Name
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('hubspotId')}
              >
                HS-KontakdId
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('studyStatus')}
              >
                BGS Status
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('studyStatus')}
              >
                WV Status
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('studyStatus')}
              >
                Startdatum
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('studyStatus')}
              >
                Enddatum
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <SelectionActions
                selectedParticipants={selectedParticipants}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleParticipantUpdateSubmit}
              ></SelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedParticipants.map((participant) => (
                <tr key={participant.participants_id} className="bg-white">
                  <td className="d-flex align-items-start long-text-column">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'delete')}
                      className="align-self-start me-3 pt-2"
                      type="checkbox"
                      checked={selectedParticipants[participant.participants_id] ?? false}
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(participant.participants_id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/participant/${participant.participants_id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {participant.title}
                    </Link>
                  </td>

                  <td>{participant.hubspotId}</td>
                  <td>
                    <BgsStatusDropdown
                      statusBGS={3}
                      handleBgsStatusUpdate={(newStatus) => handleBgsStatusUpdate(participant, newStatus)
                      }
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write')}
                    />
                  </td>
                  <td>
                    <WvStatusDropdown
                      statusWv={0}
                      handleWvStatusUpdate={(newStatus) => handleWvStatusUpdate(participant, newStatus)
                      }
                      isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write')}
                    />
                  </td>
                  <td>{'participant.currentDealStartDate'}</td>
                  <td>{'participant.currentDealEndDate'}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedParticipants.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Teilnehmer gefunden</p>
          </div>
        )}
      </div>

      {sortedParticipants.length > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              pageSize={pageSize}
              totalEntries={totalEntries}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Participants;
