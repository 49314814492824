import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Deal } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import SelectWithSearch, { SelectOption } from '../../form/SelectWithSearch';
import { AbortReasonOptions, DealStatus, DealStatusEnum, PSM, PermissionsEnum, SelfPay, TimeModel, YesNoToJaNein, } from '../../../utils/enum';
import ComboButtonGroup, { ComboButtonId } from '../../ComboButtonGroup';
import { usePermissions } from '../../../hooks/usePermissions';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

type AddEditDealProps = {
  modalTitle: string;
  buttonName: string;
  deal?: Deal;
  onSubmitSuccess: (dealId: number) => void;
};

interface FormValues {
  offerNumber: string
  offerDate: string
  macbook: number
  offerTitle: string
  actionNumber: string
  jamf: string,
  hubspotDealIdLink: string
  bgsnumber: string
  status: DealStatus
  start: string
  ue: number
  durationWeeks: number
  timeModel: string
  end: string
  uePerDay: number
  durationMonths: number
  title: string
  priceTotalGross: string
  priceTax: string
  selfPay: number
  priceTotalNet: string
  psmIncludet: number
  externalTest: number
  externalWhere: string
  externalWhen: string
  externalReg: number
  abortReason: string
  abort: string
  abortDate: string
  abortNotice: string
}

const comboButtons = [
  { id: 'general', label: 'Allgemeines' },
  { id: 'times', label: 'Zeiten' },
  { id: 'finances', label: 'Finanzen' },
  { id: 'audit', label: 'Prüfung' },
  { id: 'abort', label: 'Abbruch' },
];

const AddEditDealModal: React.FC<
  AddEditDealProps
> = ({ modalTitle, buttonName, deal, onSubmitSuccess }) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>(
    'general'
  );
  const { userHasPermissionByRight } = usePermissions();
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

  const [formValues, setFormValues] = useState({
    abort: deal?.abort ?? '',
    abortDate: deal?.abortDate || '0000-00-00 00:00:00',
    abortNotice: deal?.abortNotice ?? '',
    abortReason: deal?.abortReason ?? '',
    abortSended: deal?.abortSended || '0000-00-00 00:00:00',
    actionNumber: deal?.actionNumber ?? '',
    bgsnumber: deal?.bgsnumber ?? '',
    contractLocation: deal?.contractLocation ?? '',
    dateDataRaw: deal?.dateDataRaw ?? '',
    degreeDesignation: deal?.degreeDesignation ?? '',
    durationMonths: deal?.durationMonths ?? 0,
    durationWeeks: deal?.durationWeeks ?? 0,
    end: deal?.end || '0000-00-00 00:00:00',
    externalReg: deal?.externalReg ?? 0,
    externalTest: deal?.externalTest ?? 0,
    externalWhen: deal?.externalWhen || '0000-00-00 00:00:00',
    externalWhere: deal?.externalWhere ?? '',
    folderId: deal?.folderId ?? 0,
    hubspotDealId: deal?.hubspotDealId ?? '',
    hubspotDealIdLink: deal?.hubspotDealIdLink ?? '',
    jamf: deal?.jamf ?? "",
    listOfReleases: deal?.listOfReleases ?? '',
    listOfReleasesRemains: deal?.listOfReleasesRemains ?? '',
    macbook: deal?.macbook ?? 0,
    measureData: deal?.measureData ?? '',
    modules: deal?.modules ?? '',
    name: deal?.name ?? '',
    offerDate: deal?.offerDate || '0000-00-00 00:00:00',
    offerNumber: deal?.offerNumber ?? '',
    offerTitle: deal?.offerTitle ?? '',
    participants_id: deal?.participants_id ?? '',
    priceTax: deal?.priceTax ? deal?.priceTax?.toString().replace(/\./g, ',') : "",
    priceTotalGross: deal?.priceTotalGross ? deal?.priceTotalGross?.toString().replace(/\./g, ',') : "",
    priceTotalGrossOriginal: deal?.priceTotalGrossOriginal ?? 0,
    priceTotalNet: deal?.priceTotalNet ? deal?.priceTotalNet?.toString().replace(/\./g, ',') : "",
    priceTotalNetOriginal: deal?.priceTotalNetOriginal ?? 0,
    priceTrainingNet: deal?.priceTrainingNet ?? 0,
    psmIncludet: deal?.psmIncludet ?? 0,
    selfPay: deal?.selfPay ?? 0,
    start: deal?.start ?? '0000-00-00 00:00:00',
    status: deal?.status ?? 0,
    studyPlan: deal?.studyPlan ?? '',
    timeModel: deal?.timeModel ?? '',
    title: deal?.title ?? '',
    ue: deal?.ue ?? 0,
    ueDetails: deal?.ueDetails ?? '',
    ueInTotal: deal?.ueInTotal ?? 0,
    uePerDay: deal?.uePerDay ?? 0,
    ueWeeks: deal?.ueWeeks ?? 0,
    webhookNewDeal: deal?.webhookNewDeal ?? '',
    participants: deal?.participants ?? null,
  });

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  useEffect(() => {
    if (!showModal) {
      setFormValues(initialFormValues as any);
    }
  }, [showModal]);

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;
        if (deal?.deals_id) {
          response = await ApiClient.put(`/deals/${deal?.deals_id}`, transformFormValues(formValues));
        } else {
          response = await ApiClient.post('/deals', transformFormValues(formValues));
        }
        const dealsId = response.data.deals_id;
        onSubmitSuccess(dealsId);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        handleClose();
        setValidated(false);
      }
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [id]: value }));
  };

  function transformFormValues(values: FormValues): FormValues {
    const transformedValues = { ...values };

    if (transformedValues.priceTotalGross) {
      transformedValues.priceTotalGross = transformedValues.priceTotalGross.toString().replace(/,/g, '.')
    }

    if (transformedValues.priceTotalNet) {
      transformedValues.priceTotalNet = transformedValues.priceTotalNet.toString().replace(/,/g, '.')
    }

    if (transformedValues.priceTax) {
      transformedValues.priceTax = transformedValues.priceTax.toString().replace(/,/g, '.')
    }

    return transformedValues;
  }

  const handleSelectChange = (
    id: string,
    selectedOption: { value: string; label: string } | null
  ) => {
    setFormValues((prev: any) => ({
      ...prev,
      [id]: selectedOption ? selectedOption.value : '',
    }));
  };

  const mapParticipantsToOptions = (response: any): SelectOption[] => {
    return response.list.map((item: any) => ({
      value: item.participants_id,
      label: item.title
    }));
  };


  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    infoLabel: string = '',
  ) => {

    const formatDate = (dateStr: string = "") => {
      if (type === 'date') {
        return dateStr.split(' ')[0];
      }
      return dateStr;
    };

    return (
      <Form.Group className="mb-3 w-100" controlId={id.toString()}>
        <Form.Label style={{ color: 'black' }}>
          {label} {infoLabel && <small>{infoLabel}</small>}
        </Form.Label>
        {type === 'textarea' ? (
          <Form.Control
            as="textarea"
            style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            value={formValues[id] || ''}
            onChange={handleInputChange}
            required={required}
            isInvalid={validated && !formValues[id]}
            rows={6}
          />
        ) : (
          <Form.Control
            style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            type={type}
            value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]) : ''}
            onChange={handleInputChange}
            required={required}
            isInvalid={validated && !formValues[id]}
          />
        )}
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    );
  };

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        value={formValues[id]}
        onChange={handleInputChange}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  return (
    <>
      <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewDeals, 'write')} variant="primary" onClick={handleShow}>
        {buttonName}
      </Button>

      <Modal
        centered
        size="xl"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen='xl-down'
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={handleClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <ComboButtonGroup
                buttons={comboButtons}
                selectedCombo={selectedCombo}
                setSelectedCombo={setSelectedCombo}
                borderRadius="normal"
              ></ComboButtonGroup>
            </Col>
          </Row>

          <div className="horizontal-line my-3"></div>
          <Form noValidate validated={validated} onSubmit={submit}>

            <div
              style={{
                display:
                  selectedCombo === 'general' ? 'block' : 'none',
              }}
            >
              <>
                <Row>
                  <Col>
                    <h6>Allgemeines</h6>
                  </Col>
                  <Col>
                    {createFormGroup('offerNumber', 'Angebot/Rechnung, Nummer')}
                    {createFormGroup('title', 'Titel', 'text', true)}
                    {createFormGroup('offerDate', 'Angebot/Rechnung, Datum', 'date')}
                    {createSelectGroup(
                      'macbook',
                      'MacBook erhalten?',
                      Object.entries(YesNoToJaNein).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                    )}
                    {createFormGroup('bgsnumber', 'BGS-Nummer', 'text', true)}
                  </Col>
                  <Col>

                    {createFormGroup('hubspotDealIdLink', 'BGS-Nummer')}
                    {createFormGroup('offerTitle', 'Angebot/Rechnung, Titel')}
                    {createFormGroup('actionNumber', 'Maßnahmennummer',)}
                    {createFormGroup('jamf', 'Link zu JAMF')}
                    <SelectWithSearch
                      id="participants_id"
                      label="Teilnehmer"
                      placeholder="Suche..."
                      apiEndpoint="/participants"
                      mapResponseToOptions={mapParticipantsToOptions}
                      onChange={handleSelectChange}
                      initialValue={formValues.participants_id ? { value: formValues.participants_id, label: formValues.participants?.title! } : null}
                    />
                  </Col>
                </Row>
              </>
            </div>

            <div
              style={{
                display:
                  selectedCombo === 'times' ? 'block' : 'none',
              }}
            >
              <Row>
                <Col lg={4} style={{ color: 'black' }}>
                  <h6>Zeiten</h6>
                </Col>
                <Col >
                  {createFormGroup('start', 'Startdatum, offiziell', 'date')}
                  {createFormGroup('ue', 'Unterrichtseinheiten, gesamt')}
                  {createFormGroup('durationWeeks', 'Unterrichtszeit in Wochen')}
                  {createSelectGroup(
                    'timeModel',
                    'Unterrichtsart',
                    Object.entries(TimeModel).map(([value, label]) => ({
                      value,
                      label,
                    })),
                    'Auswählen...',
                  )}


                </Col>
                <Col >
                  {createFormGroup('end', 'Enddatum, offiziell', 'date')}
                  {createFormGroup('uePerDay', 'Unterrichtseinheiten pro Tag')}
                  {createFormGroup('durationMonths', 'Unterrichtszeit in Monaten')}
                </Col>
              </Row>
            </div>
            <div
              style={{
                display:
                  selectedCombo === 'finances' ? 'block' : 'none',
              }}
            > <>
                <Row>
                  <Col style={{ color: 'black' }}>
                    <h6>Finanzen</h6>
                  </Col>
                  <Col>
                    {createFormGroup('priceTotalGross', 'Preis, Brutto', 'text', false, '(in €)')}
                    {createFormGroup('priceTax', 'Preis, Steuern', 'text', false, '(in €)')}
                    {createSelectGroup(
                      'selfPay',
                      'Selbstzahler',
                      Object.entries(SelfPay).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                    )}
                  </Col>
                  <Col>
                    {createFormGroup('priceTotalNet', 'Preis, Netto', 'text', false, '(in €)')}
                    {createSelectGroup(
                      'psmIncludet',
                      'PSM',
                      Object.entries(PSM).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                    )}
                  </Col>
                </Row>
              </>
            </div>
            <div
              style={{
                display:
                  selectedCombo === 'audit' ? 'block' : 'none',
              }}
            >
              <>
                <Row>
                  <Col>
                    <h6>Prüfung</h6>
                  </Col>
                  <Col>
                    {createSelectGroup(
                      'externalTest',
                      'Findet eine Externe Prüfung statt?',
                      Object.entries(YesNoToJaNein).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                    )}
                    {createFormGroup('externalWhere', 'Wo wird sie abgehalten?',)}
                  </Col>
                  <Col>
                    {createFormGroup('externalWhen', 'Wann findet die statt?', 'date')}
                    {createSelectGroup(
                      'externalReg',
                      'Teilnehmer bereits registriert?',
                      Object.entries(YesNoToJaNein).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                    )}
                  </Col>
                </Row>
              </>
            </div>

            <div
              style={{
                display:
                  selectedCombo === 'abort' ? 'block' : 'none',
              }}
            >
              <>
                <Row>
                  <Col>
                    <h6>Prüfung</h6>
                  </Col>
                  <Col>
                    {createSelectGroup(
                      'abort',
                      'Deal abgebrochen?',
                      Object.entries(YesNoToJaNein).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                    )}
                    {createSelectGroup(
                      'abortReason',
                      'Grund des Abbruchs',
                      Object.entries(AbortReasonOptions).map(([value, label]) => ({
                        value,
                        label,
                      })),
                      'Auswählen...',
                    )}
                  </Col>
                  <Col>
                    {createFormGroup('abortDate', 'Datum des Abbruchs', 'date')}
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                  </Col>
                  <Col>
                    {createFormGroup('abortNotice', 'Weitere Notizen / Hinweise zum Abbruch', 'textarea')}
                  </Col>
                </Row>
              </>

            </div>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditDealModal;
