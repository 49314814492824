import React from 'react';

interface PaginationInfoProps {
    currentPage: number;
    pageSize: number;
    totalEntries: number;
  }

const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  pageSize,
  totalEntries,
}) => {
  
  const startIndex = (currentPage - 1) * pageSize + 1;
  const endIndex = Math.min(startIndex + pageSize - 1, totalEntries);

  return (
    <div className="dataTables_info">
      {startIndex}-{endIndex} von {totalEntries} Einträgen
    </div>
  );
};

export default PaginationInfo;