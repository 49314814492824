import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Stage, Container, Sprite, Text } from '@pixi/react';
import * as PIXI from 'pixi.js';
import { TextStyle } from 'pixi.js';
import testImage from '../assets/images/bgs.png';
import stempelLogo from '../assets/images/stempel+logo.png';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairsSimple, faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons';

const labels = {
  measureActionNrLeft: "Maßnahmennummer, links",
  measureActionNrMiddle: "Maßnahmennummer, mitte",
  measureActionNrRight: "Maßnahmennummer, rechts",
  measureDesignation: "Maßnahmenbezeichnung",
  measureStart: "Maßnahmendauer, Beginn",
  measureEnd: "Maßnahmendauer, Ende",
  measureParticipant: "Maßnahmendauer, Teilnehmer (Name)",
  measureParticipationStart: "Teilnahme, Beginn",
  measureParticipationEnd: "Teilnahme, Ende",
  measureDurationWeeks: "Dauer in Wochen",
  measureModules: "Maßnahmenbausteine",
  measureLocationDate: "Ort und Datum",
  imageScale: "Logo + Signatur",
};

const fontSizeOptions = [
  { value: '12', label: '12' },
  { value: '16', label: '16' },
  { value: '20', label: '20' },
  { value: '24', label: '24' },
  { value: '28', label: '28' },
  { value: '32', label: '32' },
  { value: '36', label: '36' },
  { value: '40', label: '40' },
  { value: '44', label: '44' },
  { value: '48', label: '48' },
  { value: '52', label: '52' },
];

const imageScaleOptions = [
  { value: '0.25', label: 'Bildgröße: 25%' },
  { value: '0.50', label: 'Bildgröße: 50%' },
  { value: '0.75', label: 'Bildgröße: 75%' },
  { value: '1.00', label: 'Originalgröße (100%)' },
  { value: '1.25', label: 'Bildgröße: 125%' },
  { value: '1.50', label: 'Bildgröße: 150%' },
];

interface ImageEditorProps {
  imageUrl: string;
  initialFormValues?: FormValues;
  inEditMode: boolean;
  onFormChange?: (hasChanged: boolean) => void;
}

interface TextData {
  content?: string;
  x: number;
  y: number;
  fontSize?: number;
  scale?: number;
}

interface FormValues {
  measureActionNrLeft: TextData;
  measureActionNrMiddle: TextData;
  measureActionNrRight: TextData;
  measureDesignation: TextData;
  measureStart: TextData;
  measureEnd: TextData;
  measureParticipant: TextData;
  measureParticipationStart: TextData;
  measureParticipationEnd: TextData;
  measureDurationWeeks: TextData;
  measureModules: TextData;
  measureLocationDate: TextData;
  imageScale: TextData;
}

const defaultFormValues: FormValues = {
  measureActionNrLeft: { content: '', x: 0, y: 0, fontSize: 44 },
  measureActionNrMiddle: { content: '', x: 0, y: 0, fontSize: 44 },
  measureActionNrRight: { content: '', x: 0, y: 0, fontSize: 44 },
  measureDesignation: { content: '', x: 0, y: 0, fontSize: 44 },
  measureStart: { content: '', x: 0, y: 0, fontSize: 44 },
  measureEnd: { content: '', x: 0, y: 0, fontSize: 44 },
  measureParticipant: { content: '', x: 0, y: 0, fontSize: 44 },
  measureParticipationStart: { content: '', x: 0, y: 0, fontSize: 44 },
  measureParticipationEnd: { content: '', x: 0, y: 0, fontSize: 44 },
  measureDurationWeeks: { content: '', x: 0, y: 0, fontSize: 44 },
  measureModules: { content: '', x: 0, y: 0, fontSize: 44 },
  measureLocationDate: { content: '', x: 0, y: 0, fontSize: 44 },
  imageScale: { x: 0, y: 0, scale: 0.5 },
};

export interface ImageEditorRef {
  createFile: () => Promise<File | null>;
  saveData: () => void;
  resetFormValues: () => void;
  loadInitialData: () => void
}

const ImageEditor: React.ForwardRefRenderFunction<ImageEditorRef, ImageEditorProps> = ({ imageUrl, initialFormValues, inEditMode, onFormChange }, ref) => {
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues || defaultFormValues);
  const [initialValues, setInitialValues] = useState<FormValues>({
    ...formValues,
  });

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialValues);
  };

  const [selectedTextId, setSelectedTextId] = useState<keyof FormValues | 'imageScale' | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [globalFontSize, setGlobalFontSize] = useState('');

  const stageRef = useRef<PIXI.Application | null>(null);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setSelectedTextId(null);
      }
    };
    document.addEventListener('keydown', handleEsc);
    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      calculateOptimalFontSize(img.width, img.height)
    };
  }, [imageUrl]);

  useEffect(() => {
    if (onFormChange) {
      onFormChange(checkIfDataChanged());
    }
  }, [formValues]);


  useEffect(() => {
    if (!inEditMode) {
      setSelectedTextId(null)
    }
  }, [inEditMode]);

  useImperativeHandle(ref, () => ({
    createFile,
    saveData,
    resetFormValues,
    loadInitialData
  }));

  const calculateOptimalFontSize = (imageWidth: number, imageHeight: number) => {
    const smallerDimension = Math.min(imageWidth, imageHeight);
    const optimalFontSize = Math.max(12, Math.floor(smallerDimension / 50));
    const closestFontSize = getClosestFontSize(optimalFontSize, fontSizeOptions)
    handleGlobalFontSizeChange(closestFontSize)
  };

  const getClosestFontSize = (optimalFontSize: number, fontSizeOptions: { value: string; label: string }[]): number => {
    return parseInt(fontSizeOptions.reduce((prev, curr) => {
      return Math.abs(parseInt(curr.value) - optimalFontSize) < Math.abs(parseInt(prev.value) - optimalFontSize) ? curr : prev;
    }).value, 10);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id as keyof FormValues],
        content: value,
      },
    });
  };

  const handleFontSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = event.target;
    setFormValues({
      ...formValues,
      [id]: {
        ...formValues[id as keyof FormValues],
        fontSize: parseFloat(value),
      },
    });
  };

  const handleGlobalFontSizeChange = (eventOrValue: React.ChangeEvent<HTMLSelectElement> | number) => {
    let newFontSize: number;

    if (typeof eventOrValue === 'number') {
      newFontSize = eventOrValue;
      setGlobalFontSize(newFontSize.toString());
    } else {
      const selectedValue = eventOrValue.target.value;
      newFontSize = parseFloat(selectedValue);
      setGlobalFontSize(selectedValue);

      if (selectedValue === "") {
        return;
      }
    }

    const updatedFormValues = Object.keys(formValues).reduce((acc, key) => {
      acc[key as keyof FormValues] = {
        ...formValues[key as keyof FormValues],
        fontSize: newFontSize,
      };
      return acc;
    }, {} as FormValues);

    setFormValues(updatedFormValues);
  };

  const handleImageScaleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const scale = parseFloat(event.target.value);
    setFormValues({
      ...formValues,
      imageScale: {
        ...formValues.imageScale,
        scale: scale,
      },
    });
  };

  const resetFormValues = () => {
    const updatedFormValues = Object.keys(formValues).reduce((acc, key) => {
      acc[key as keyof FormValues] = {
        ...formValues[key as keyof FormValues],
        x: 0,
        y: 0,
        scale: 0.5
      };
      return acc;
    }, {} as FormValues);

    setFormValues(updatedFormValues);
  };

  const handleStageClick = (event: any) => {
    if (selectedTextId !== null) {
      const { offsetX: x, offsetY: y } = event.nativeEvent;
      if (selectedTextId === 'imageScale') {
        setFormValues({
          ...formValues,
          imageScale: {
            ...formValues.imageScale,
            x: x / zoom,
            y: y / zoom,
          },
        });
      } else {
        const selectedText = formValues[selectedTextId];
        setFormValues({
          ...formValues,
          [selectedTextId]: {
            ...selectedText,
            x: x / zoom,
            y: y / zoom,
          },
        });
      }
    }
  };

  const saveData = () => {
    localStorage.setItem('formValues', JSON.stringify(formValues));
    setSelectedTextId(null);
  };

  const loadInitialData = () => {
    if (initialFormValues) {
      setFormValues(initialFormValues);
      setInitialValues(initialFormValues)
    } else {
      setFormValues(defaultFormValues);
      setInitialValues(defaultFormValues)
    }
  };

  const createFile = (): Promise<File | null> => {
    return new Promise((resolve, reject) => {
      if (stageRef.current) {
        const canvas = stageRef.current.renderer.extract.canvas(stageRef.current.stage);
        canvas.toBlob!((blob: Blob | null) => {
          if (blob) {
            const file = new File([blob], 'bgs-filled.png', { type: 'image/png' });
            resolve(file);
          } else {
            resolve(null);
          }
        }, 'image/png');
      } else {
        resolve(null);
      }
    });
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  };

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    infoLabel: string = '',
  ) => {
    return (
      <Form.Group className="w-100" controlId={id}>
        {label && (
          <Form.Label style={{ color: 'black' }}>
            {label} {infoLabel && <small>{infoLabel}</small>}
          </Form.Label>
        )}
        {type === 'textarea' ? (
          <Form.Control
            as="textarea"
            style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            value={formValues[id].content}
            onChange={handleInputChange}
            required={required}
            rows={6}
          />
        ) : (
          <Form.Control
            style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            type={type}
            value={formValues[id].content}
            onChange={handleInputChange}
            required={required}
          />
        )}
      </Form.Group>
    );
  };

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: { value: string; label: string }[],
    placeholder?: string,
    required = false,
  ) => (
    <Form.Group style={{ color: 'black', width: '80px' }} controlId={id}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        value={formValues[id].fontSize}
        onChange={handleFontSizeChange}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const createImageScaleGroup = (
    label: string,
    options: { value: string; label: string }[],
  ) => (
    <Form.Group className='mb-5' style={{ color: 'black' }} controlId="imageScale">
      <Form.Label style={{ color: 'black', marginRight: '10px' }}>{label}</Form.Label>
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <Button
            className={`${selectedTextId === 'imageScale' ? 'btn-primary pulsate' : 'btn-soft-primary'} round-modal-close-button me-2`}
            style={{ padding: 0 }}
            onClick={() => (selectedTextId === 'imageScale' ? setSelectedTextId(null) : setSelectedTextId('imageScale'))}
          >
            <FontAwesomeIcon icon={faCrosshairsSimple} size="sm" />
          </Button>
        </div>
        <Form.Select
          value={formValues.imageScale.scale?.toFixed(2)}
          onChange={handleImageScaleChange}
          style={{ backgroundColor: '#F9F9F9', color: 'black' }}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      </div>
    </Form.Group>
  );

  const createFormRow = (
    id: keyof FormValues,
    label: string,
    fontSizeOptions: { value: string; label: string }[],
    placeholder?: string,
    required = false,
  ) => (
    <div className="d-flex flex-column mb-5" key={id}>
      <Form.Label style={{ color: 'black', marginRight: '10px' }}>{label}</Form.Label>
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <Button
            className={`${selectedTextId === id ? 'btn-primary pulsate' : 'btn-soft-primary'} round-modal-close-button me-2`}
            style={{ padding: 0 }}
            onClick={() => (selectedTextId === id ? setSelectedTextId(null) : setSelectedTextId(id))}
          >
            <FontAwesomeIcon icon={faCrosshairsSimple} size="sm" />
          </Button>
        </div>
        <div className="me-2">{createSelectGroup(id, '', fontSizeOptions, placeholder, required)}</div>
        {createFormGroup(id, '', id === 'measureModules' ? 'textarea' : 'text', required)}
      </div>
    </div>
  );

  return (
    <Row className="h-100">
      <Col className="p-0 h-100" lg={inEditMode ? 8 : 12} style={{ position: 'relative' }}>
        <div className="p-0 h-100" style={{ overflow: 'auto', cursor: selectedTextId ? 'crosshair' : '' }}>
          <Stage
            width={imageDimensions.width * zoom}
            height={imageDimensions.height * zoom}
            options={{ backgroundColor: 0xffffff }}
            onPointerDown={handleStageClick}
            onMount={(app) => {
              stageRef.current = app;
            }}
          >
            <Container scale={{ x: zoom, y: zoom }}>
              <Sprite image={testImage} />

              {formValues.imageScale.x !== 0 && formValues.imageScale.y !== 0 &&
                <Sprite
                  image={stempelLogo}
                  anchor={{ x: 0.5, y: 0.5 }}
                  x={formValues.imageScale.x}
                  y={formValues.imageScale.y}
                  scale={{ x: formValues.imageScale?.scale!, y: formValues.imageScale?.scale! }}
                />}

              {Object.values(formValues).map((field, index) =>
                'content' in field && field.x && field.y ? (
                  <Text
                    key={index}
                    text={field.content}
                    x={field.x}
                    y={field.y}
                    anchor={{ x: 0.5, y: 0.5 }}
                    style={
                      new TextStyle({
                        fontSize: field.fontSize,
                        fill: 'black',
                      })
                    }
                  />
                ) : null
              )}
            </Container>
          </Stage>
        </div>
        <div style={{ position: 'absolute', top: 20, right: 20, zIndex: 10 }}>
          <Button
            variant="primary"
            className="round-modal-close-button mb-2"
            style={{ padding: 0 }}
            onClick={handleZoomOut}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassMinus} size="sm" />
          </Button>
          <Button
            variant="primary"
            className="round-modal-close-button me-2"
            style={{ padding: 0 }}
            onClick={handleZoomIn}
          >
            <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="sm" />
          </Button>
        </div>
      </Col>

      {inEditMode && (
        <Col lg={4} className="h-100" style={{ overflowY: 'auto' }}>
          <div className="bg-white p-3">
            <Form.Group className="text-black w-100 my-3">
              <Form.Label>Globale Schriftgröße</Form.Label>
              <Form.Select
                value={globalFontSize}
                onChange={handleGlobalFontSizeChange}
                style={{ backgroundColor: '#F9F9F9', color: 'black' }}
              >
                <option value="">Bitte wählen</option>
                {fontSizeOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <p>Wähle eine globale Schriftgröße aus, um jede individuelle Schriftgröße zu überschreiben.</p>
            <div className="horizontal-line my-5"></div>
            <h5>Felder ausfüllen & platzieren</h5>
            <p>
              Fülle ein Textfeld aus, ändere ggf. die Schriftgröße und platziere es mit dem Setzen-Symbol{' '}
              <FontAwesomeIcon className="text-primary" icon={faCrosshairsSimple} size="sm" /> an eine beliebige Position im
              Dokument.
            </p>

            {Object.keys(formValues).map((key) =>
              key !== 'imageScale' ? createFormRow(key as keyof FormValues, labels[key as keyof typeof labels], fontSizeOptions) : null,
            )}
            {createImageScaleGroup('Logo + Signatur', imageScaleOptions)}
          </div>
        </Col>
      )}
    </Row>
  );
};

export default forwardRef(ImageEditor);
