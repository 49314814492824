import { useState, useMemo } from 'react';

type SortConfig<T> = {
  key: keyof T | null;
  direction: 'ascending' | 'descending';
};

export const useSortableData = <T,>(items: T[], initialSortConfig: SortConfig<T> = { key: null, direction: 'ascending' }) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>(initialSortConfig);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key!] < b[sortConfig.key!]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key!] > b[sortConfig.key!]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: keyof T) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
