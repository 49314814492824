import { Permissions } from '../interfaces';

export const CertificationStatusEnum = {
  0: 'Benötigt Bearbeitung',
  1: 'Benötigt Zertifizierung',
  2: 'In Zertifizierung',
  3: 'Zertifiziert',
};

export type CertificationStatus = keyof typeof CertificationStatusEnum;

export const PublicationStatusEnum = {
  1: 'Veröffentlicht',
  2: 'Unveröffentlichte Änderungen',
  0: 'Unveröffentlicht',
  3: 'Archiviert'
};

export type PublicationStatus = keyof typeof PublicationStatusEnum;

export const AttendanceStatusEnum = {
  0: 'Prüfung erforderlich',
  1: 'Alles in Ordnung',
};

export type AttendanceStatus = keyof typeof AttendanceStatusEnum;

export const YesNoToJaNein = {
  1: 'Ja',
  0: 'Nein',
};

export const ExecutionForm = {
  1: 'Vollzeit',
  2: 'Teilzeit',
  3: 'Vollzeit + Teilzeit',
};

export const TeachingForms = {
  1: 'Online',
  2: 'Präsens',
  3: 'Hybrid',
};

export const FormOfExecution = {
  1: 'Einzelmaßnahme',
  2: 'Gruppenmaßnahme',
};

export const TypeOfMeasure = {
  1: 'Maßnahme',
  2: 'Maßnahmenbaustein',
};

export enum ActiveDraft {
  active = 'Aktiv',
  draft = 'Entwurf',
}

export const TypeOfLocationEnum = {
  1: 'Eigener Standort',
  2: 'Partnerstandort',
};

export type TypeOfLocation = keyof typeof TypeOfLocationEnum;

export const ContractRentalStatus = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export enum GermanStates {
  bw = 'Baden-Württemberg',
  by = 'Bayern',
  be = 'Berlin',
  bb = 'Brandenburg',
  hb = 'Bremen',
  hh = 'Hamburg',
  he = 'Hessen',
  mv = 'Mecklenburg-Vorpommern',
  ni = 'Niedersachsen',
  nw = 'Nordrhein-Westfalen',
  rp = 'Rheinland-Pfalz',
  sl = 'Saarland',
  sn = 'Sachsen',
  st = 'Sachsen-Anhalt',
  sh = 'Schleswig-Holstein',
  th = 'Thüringen',
}

export enum Countries {
  al = 'Albanien',
  ad = 'Andorra',
  am = 'Armenien',
  at = 'Österreich',
  az = 'Aserbaidschan',
  be = 'Belgien',
  ba = 'Bosnien und Herzegowina',
  bg = 'Bulgarien',
  by = 'Weißrussland',
  ch = 'Schweiz',
  cy = 'Zypern',
  cz = 'Tschechien',
  de = 'Deutschland',
  dk = 'Dänemark',
  ee = 'Estland',
  es = 'Spanien',
  fo = 'Färöer-Inseln',
  fi = 'Finnland',
  fr = 'Frankreich',
  gb = 'Vereinigtes Königreich',
  ge = 'Georgien',
  gi = 'Gibraltar',
  gr = 'Griechenland',
  hu = 'Ungarn',
  hr = 'Kroatien',
  ie = 'Irland',
  is = 'Island',
  it = 'Italien',
  kz = 'Kasachstan',
  xk = 'Kosovo',
  lv = 'Lettland',
  li = 'Liechtenstein',
  lt = 'Litauen',
  lu = 'Luxemburg',
  mk = 'Nordmazedonien',
  mt = 'Malta',
  md = 'Moldawien',
  mc = 'Monaco',
  me = 'Montenegro',
  nl = 'Niederlande',
  no = 'Norwegen',
  pl = 'Polen',
  pt = 'Portugal',
  ro = 'Rumänien',
  ru = 'Russland',
  sm = 'San Marino',
  rs = 'Serbien',
  sk = 'Slowakei',
  si = 'Slowenien',
  se = 'Schweden',
  tr = 'Türkei',
  ua = 'Ukraine',
  va = 'Vatikanstadt',
}

export const IndustryBranch = {
  '1': 'Gewerblich-technischer Bereich',
  '2': 'Kaufmännischer Bereich',
  '3': 'Unternehmensbezogene Dienstleistungen',
  '4': 'Personenbezogene und soziale Dienstleistungen',
} as const;

export const EducationType = {
  '0': 'Keine Zuordnung möglich',
  '100': 'Allgemeinbildung',
  '101': 'Berufliche Grundqualifikation',
  '102': 'Berufsausbildung',
  '103': 'Gesetzlich/gesetzesähnlich geregelte Fortbildung/Qualifizierung',
  '104': 'Fortbildung/Qualifizierung',
  '105': 'Nachholen des Berufsabschlusses',
  '106': 'Rehabilitation',
  '109': 'Umschulung',
} as const;

export const InstitutionType = {
  '0': 'Keine Zuordnung möglich',
  '100': 'Allgemeinbildende Schule/Einrichtung',
  '101': 'Berufsakademie / Duale Hochschule',
  '102': 'Berufsbildende Schule/Einrichtung',
  '103': 'Berufsbildungswerk (R)',
  '104': 'Berufsförderungswerk (R)',
  '105': 'Einrichtung der beruflichen Weiterbildung',
  '106': 'Fachhochschule / Hochschule für angewandte Wissenschaften',
  '107': 'Kunst- und Musikhochschule',
  '108': 'Universität',
  '109': 'vergleichbare Rehabilitationseinrichtung (R)',
  '110': 'med.-berufl. Rehabilitationseinrichtung (R)',
  '111': 'Verwaltungshochschule',
  '112': 'Kirchliche Hochschule',
  '113': 'Private Hochschule',
  '114': 'Hochschule eigenen Typs',
} as const;

export const EducationTypeProductWebsite = {
  avgs: 'AVGS',
  weiterbildung: 'Weiterbildung',
  umschulung: 'Umschulung',
} as const;

export const KategoryProductWebsite = {
  'PC-ITP-1': 'IT und Digitalisierung',
  'PC-MV-1': 'Marketing',
  'PC-P-1': 'Projektmanagement',
} as const;

export enum PermissionsEnum {
  Dashboard = 'dashboard',
  ViewDeals = 'deals',
  ViewParticipants = 'participants',
  ViewProducts = 'products',
  ViewMedia = 'media',
  ViewSettings = 'settings',
  ViewUsers = 'users',
  ViewRoles = 'roles',
  ViewWebhooks = 'webhooks',
  ViewApiKeys = 'apikeys',
  ViewSatisfactions = 'satisfactions',
  ViewActivities = 'activities',
  ViewAttendances = 'attendances',
}

export const CompanyEnum = {
  oc: 'OneCareer',
  cn: 'ChapterNext',
};

export type Company = keyof typeof CompanyEnum;

export const MediaPlaceholderImages = {
  pdf: 'https://storage.onecareer.de/placeholder-media-format-pdf.webp',
  word: 'https://storage.onecareer.de/placeholder-media-format-word.webp',
  excel: 'https://storage.onecareer.de/placeholder-media-format-excel.webp',
  music: 'https://storage.onecareer.de/placeholder-media-format-music.webp',
  video: 'https://storage.onecareer.de/placeholder-media-format-video.webp',
};

export const SettingTypeMap = {
  contractEmailTemplate: '1',
  globalMeasureDetails: '2',
};

export const UserStatusEnum = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export type UserStatus = keyof typeof UserStatusEnum;

export const CertifierEnum = {
  acadCert: 'acadCert',
};

export const RoleStatusEnum = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export type RoleStatus = keyof typeof RoleStatusEnum;

export const moduleTranslations: Record<keyof Permissions, string> = {
  settings: 'Einstellungen',
  users: 'Nutzer Verwaltung',
  roles: 'Rollen Verwaltung',
  dashboard: 'Dashboard',
  companies: 'Unternehmen',
  deals: 'Deals',
  participants: 'Teilnehmer',
  products: 'Produkte',
  webhooks: 'Webhooks',
  apikeys: 'API-Schlüssel',
  media: 'Medien',
  satisfactions: 'Zufriedenheiten',
  activities: 'Aktivitäten',
  attendances: 'Anwesenheiten'
};

export const participantLabels = {
  city: 'Stadt',
  created: 'Erstellungsdatum',
  discordId: 'Discord-ID',
  email: 'E-Mail Adresse',
  emailScnd: 'Sekundäre E-Mail Adresse',
  firstname: 'Vorname',
  media_id: 'Ordner-ID (Medien-ID)',
  hubspotId: 'Hubspot-Kontakt-ID',
  lastname: 'Nachname',
  lwId: 'Lernwelt-ID',
  modified: 'Änderungsdatum',
  name: 'Name',
  notice: 'Notizen',
  phoneNumber: 'Telefonnummer',
  salutation: 'Anrede',
  secondName: 'Zweitname',
  studyStatus: 'Status (Lernwelt)',
  street: 'Straße + Hausnummer',
  theTitle: 'Titel (Anrede)',
  title: 'Titel',
  zipCode: 'Postleitzahl',
};

export const WebflowSyncModuleEnum = {
  locations: 'Standorte',
  products: 'Produkte',
};

export const BgsStatusEnum = {
  0: 'Nicht vorhanden',
  1: 'Blanko',
  2: 'Ausgefüllt',
  3: 'An AfA verschickt',
};

export type BgsStatus = keyof typeof BgsStatusEnum;

export const WvStatusEnum = {
  0: 'Nicht erstellt',
  1: 'Unterschriftslos',
  2: 'An Teilnehmer verschickt',
  3: 'Vom TN Geöffnet',
  4: 'Abgebrochen',
  5: 'Unterschrieben',
  6: 'An AfA verschickt'
};

export type WvStatus = keyof typeof WvStatusEnum;

export const StudyStatusEnum = {
  0: 'Gestoppt/Abgebrochen',
  1: 'Abgeschlossen',
  2: 'Lernend',
  3: 'Neuer Teilnehmer',
};

export type StudyStatus = keyof typeof StudyStatusEnum;

export const DealStatusEnum = {
  0: 'Gestoppt/Abgebrochen',
  1: 'Abgeschlossen',
  2: 'Lernend',
  3: 'Neu',
};

export type DealStatus = keyof typeof DealStatusEnum;

export const SalutationEnum = {
  0: 'Nicht bekannt',
  1: 'Herr',
  2: 'Frau',
  3: 'Divers',
};

export const TitleOptions = {
  dr: 'Dr.',
  dr_med: 'Dr. med.',
  prof: 'Prof.',
  prof_dr: 'Prof. Dr.',
  dipl_ing: 'Dipl.-Ing.',
  mag: 'Mag.',
  ma: 'M.A.',
  ba: 'B.A.',
  msc: 'M.Sc.',
  bsc: 'B.Sc.',
  phd: 'Ph.D.',
  md: 'M.D.',
  ing: 'Ing.',
  '-': 'Nicht bekannt',
};

export const TimeModel = {
  fulltime: 'Vollzeit',
  parttime: 'Teilzeit',
};

export const SelfPay = {
  0: 'Kein Selbstzahler',
  1: 'Selbstzahler',
};

export const PSM = {
  0: 'Keine PSM Prüfung',
  1: 'PSM wird vom Bildungsträger übernommen',
};

export const AbortReasonOptions = {
  workFound: 'Neuen Job gefunden',
  workFoundNotParttime: 'Neuen Job gefunden - Nicht berufsbegleitend',
  rival: 'Für einen anderen Träger entschieden',
  lostValidity: 'BGS hat Gültigkeit verloren',
  lost: 'BGS verloren / abhanden gekommen',
  cancellation: 'Stornierung',
  terminationbyProvider: 'Beendigung seitens Anbieter',
  notStarted: 'Nicht gestartet / Deal geplatzt',
  notKnown: 'Grund nicht bekannt',
};

export const ProductTypesEnum = {
  educations: 'Weiterbildungen',
  courses: 'Kurse',
  combinations: 'Kombinationen',
  modules: 'Module',
  learningunits: 'Lerneinheiten',
};

export type ProductTypes = keyof typeof ProductTypesEnum;

export const ProcessOptions1 = {
  '-': 'Keinen Prozess initialisieren!',
  allAudit: 'Alle Audit-Dokumente generieren',
  createDateData: 'Zeit-Datensatz erstellen',
  measureAttachment: 'Anlage des Maßnahmenträgers generieren',
  presences: 'Anwesenheitsliste/n generieren',
  presencesGaps: 'Anwesenheitsliste/n auffüllen (Spezial)',
  releases: 'Modulliste neu generieren',
  satisfaction: 'Kundenbewertung/en generieren',
  studyplan: 'Lehrplan / Lehrpläne generieren',
  unlockEducation: 'Lernwelt freischalten',
};

export const ProcessOptions2 = {
  newLw1Create: '(1) Teilnehmer Freischalten/aktivieren',
  newLw2Modules: '(2) Alle verfügbaren Kurs/Module aktivieren',
};

export const WebhookStatusEnum = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export type WebhookStatus = keyof typeof WebhookStatusEnum;

export const WebhookModuleEnum = {
  'participants': 'Teilnehmer',
};

export const searchFilterTranslations: { [key: string]: string } = {
  all: 'Alle'
};

export const ApiKeyStatusEnum = {
  0: 'Inaktiv',
  1: 'Aktiv',
};

export type ApiKeyStatus = keyof typeof ApiKeyStatusEnum;


export const SatisfactionQuestionsEnum = {
  "virtual-room": "Wie bewertest Du Deine Erfahrung im virtuellen Raum während der Weiterbildung?",
  "professional-knowledge": "Wie bewertest Du die fachliche Kenntnis Deines Dozenten?",
  "pedagogical-competence": "Wie bewertest Du die pädagogischen Kompetenzen Deines Dozenten?",
  "organisation": "Wie bewertest Du die Organisation und den Ablauf der Weiterbildung?",
  "learning-environment": "Wie bewertest Du die Lernumgebung?",
  "benefit-of-training": "Wie bewertest Du den Nutzen der Weiterbildung für Deine Zukunft?"
};

export type SatisfactionQuestions = keyof typeof SatisfactionQuestionsEnum;


export function getEnumValue<T extends Record<string, any>>(
  enumObj: T,
  key: string | null | undefined
): string {
  if (key === null || key === undefined) {
    return '-';
  }
  return key in enumObj ? String(enumObj[key]) : key;
}
