import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import DeleteModal from '../DeleteModal';

interface SelectionActionsProps {
  selectedCount: number;
  selectedItems: any;
  handleDeSelectAll: () => void;
  onSubmitSuccess: () => void;
}

const SelectionActions: React.FC<SelectionActionsProps> = ({
  selectedCount,
  selectedItems,
  handleDeSelectAll,
  onSubmitSuccess,
}) => {
  return (
    <tr>
      <td colSpan={7}>
        <Row
          className="d-flex justify-content-between p-3"
          style={{ backgroundColor: '#EBEEFD' }}
        >
          <Col className="d-flex align-items-center" md={2}>
            <span>{selectedCount} Elemente wurden ausgewählt</span>
            <Button variant="link" onClick={handleDeSelectAll}>
              Auswahl aufheben
            </Button>
          </Col>
          <Col className="d-flex justify-content-end" md={8}>
            <DeleteModal
              modalTitle="Löschen?"
              buttonName="Löschen"
              entityType='attendances'
              entityIds={Object.keys(selectedItems)
                .filter(key => selectedItems[key] === true)
                .map(Number)}
              onSubmit={onSubmitSuccess}>
            </DeleteModal>
          </Col>
        </Row>
      </td>
    </tr>
  );
};

export default SelectionActions;
