import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Button, } from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { useSortableData } from '../../hooks/useSortableData';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Satisfaction } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import Card from '../../components/bootstrap/card';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import { useSelection } from '../../hooks/useSelection';
import SearchInput from '../../components/SearchInput';
import SelectionActions from '../../components/satisfactions/SelectionActions';
import { getSatisfactionIcon } from '../../utils/satisfactions/utils';


export const mockSatisfactions: Satisfaction[] = [
  {
    satisfactions_id: 1,
    title: 'Zufriedenheitsbefragung - 2024-07-02 - Birgit Strobel',
    averageRating: '10,00',
    participant: 'Birgit Strobel',
    participantEmail: 'birgit.e.strobel@t-online.de',
    courseName: 'Einführung in die Digitalisierung für Einsteiger',
    comment: 'very good course very good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good coursevery good course',
    ratings: '{"virtual-room":"4","professional-knowledge":"5","pedagogical-competence":"5","organisation":"6","benefit-of-training":"4"}',
    amountQuestions: '6'
  },
];

interface SatisfactionsResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Satisfaction[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Satisfactions = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [satisfactions, setSatisfactions] = useState<Satisfaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedSatisfactions, requestSort } = useSortableData(satisfactions);

  const getId = useCallback((satisfaction: Satisfaction) => satisfaction.satisfactions_id, []);

  const {
    selectedItems: selectedSatisfactions,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(satisfactions, getId);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    }
  };
  const fetchSatisfactions = useCallback(async () => {
    setSatisfactions([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (searchQuery) {
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      // const response = await ApiClient.get(`/satisfactions${queryParams}`);
      // const satisfactionsResponse = response.data as SatisfactionsResponse
      // setTotalPages(satisfactionsResponse.amountPages);
      // setSatisfactions(satisfactionsResponse.list);
      // setCurrentPage(satisfactionsResponse.page);
      // setPageSize(satisfactionsResponse.itemsPerPage);
      // setTotalEntries(satisfactionsResponse.amountAllItems);
      //setAvailableFilter(satisfactionsResponse.searchFilters)
      setSatisfactions(mockSatisfactions);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage, searchQuery]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'read');

      if (hasPermission) {
        fetchSatisfactions();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchSatisfactions,
    permissionsLoaded,
    location,
    searchQuery
  ]);


  const handleSatisfactionUpdateSubmit = () => {
    fetchSatisfactions();
  };


  const resetSearch = () => {
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);


  useEffect(() => {
    resetSearch();
  }, [location]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Zufriedenheiten</h3>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={setSelectedCombo}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('courseName')}
              >
                Kurs
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('participant')}
              >
                Teilnehmer
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('averageRating')}
              >
                Bewertung
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('averageRating')}
              >
                Auswertung
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('ratings')}
              >
                Anzahl Fragen
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <SelectionActions
                selectedItems={selectedSatisfactions}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleSatisfactionUpdateSubmit}
              ></SelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedSatisfactions.map((satisfaction) => (
                <tr key={satisfaction.satisfactions_id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'write') && !userHasPermissionByRight(PermissionsEnum.ViewSatisfactions, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedSatisfactions[satisfaction.satisfactions_id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(satisfaction.satisfactions_id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/satisfactions/${satisfaction.satisfactions_id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {satisfaction.title}
                    </Link>
                  </td>
                  <td>{satisfaction.courseName}</td>
                  <td>{satisfaction.participant}</td>
                  <td className='text-center'>{getSatisfactionIcon(Number(satisfaction.averageRating))}</td>
                  <td className='text-center'><span className='fw-bold'>{satisfaction.averageRating}</span> von 10</td>
                  <td className='text-center'>{satisfaction.amountQuestions}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedSatisfactions.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Zufriedenheiten gefunden</p>
          </div>
        )}
      </div>

      {sortedSatisfactions.length > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              pageSize={pageSize}
              totalEntries={totalEntries}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Satisfactions;
