import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faBan, faCheck, faSpinner, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { StudyStatus, StudyStatusEnum } from '../utils/enum';

interface StudyStatusIndicatorProps {
    studyStatus: StudyStatus;
}

const studyStatusIconMap: { [key: number]: IconDefinition } = {
    0: faBan,
    1: faCheck,
    2: faSpinner,
    3: faUserPlus,
};

const studyStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-success',
    2: 'text-warning',
    3: 'text-primary',
};

const StudyStatusIndicator: React.FC<StudyStatusIndicatorProps> = ({ studyStatus }) => {
    const studyStatusIcon = studyStatusIconMap[Number(studyStatus)];
    const studyStatusColor = studyStatusColorMap[Number(studyStatus)];
    const studyStatusText = StudyStatusEnum[Number(studyStatus) as StudyStatus];

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${studyStatusColor}`}>
            <FontAwesomeIcon icon={studyStatusIcon} className="me-2" />
            <span>{studyStatusText}</span>
        </div>
    );
};

export default StudyStatusIndicator;
