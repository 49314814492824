interface ParticipantInitialsComponentProps {
    initials: string;
    size?: number;
}

const ParticipantInitialsComponent: React.FC<ParticipantInitialsComponentProps> = ({ initials, size = 60 }) => {
    const fontSize = size * 0.4;
    return (
        <div className="participant-initials" style={{ width: size, height: size }}>
            <span className="participant-initials-text" style={{ fontSize }}>{initials}</span>
        </div>
    );
}

export default ParticipantInitialsComponent;
