import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
    InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { IGlobalMeasureDetails, Setting } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

type GlobalMeasureDetailsModalProps = {
    modalTitle: string;
    buttonName: string;
    setting?: Setting;
    onSubmitSuccess: (settingsId: number) => void;
    isDisabled?: boolean
};

interface FormValues {
    title: string;
    description: string;
    ident: string;
    details: IGlobalMeasureDetails;
}

const GlobalMeasureDetailsModal: React.FC<GlobalMeasureDetailsModalProps> = ({
    modalTitle,
    buttonName,
    setting,
    onSubmitSuccess,
    isDisabled
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newKey, setNewKey] = useState<string>("");
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);

    const [formValues, setFormValues] = useState<FormValues>({
        title: setting?.title ?? '',
        description: setting?.description ?? '',
        ident: setting?.ident ?? '',
        details: setting?.details as IGlobalMeasureDetails ?? {}
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    useEffect(() => {
        if (!showModal) {
            setFormValues(initialFormValues);
        }
    }, [showModal]);

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                const response = await ApiClient.put(
                    `/settings/${setting?.settings_id}`,
                    prepareFormDataForSubmit(formValues)
                );
                const id = response.data.settings_id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                handleClose();
                setValidated(false);
            }
        }
    };

    const prepareFormDataForSubmit = (formValues: FormValues) => {
        return {
            title: formValues.title,
            ident: formValues.ident,
            description: formValues.description,
            details: JSON.stringify(formValues.details)
        };
    };

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;

        // Handle measure details separately
        if (id.startsWith('details')) {
            const [, key, field] = id.split('.');
            setFormValues(prev => ({
                ...prev,
                details: {
                    ...prev.details,
                    [key]: {
                        ...prev.details[key],
                        [field]: value
                    }
                }
            }));
        } else {
            setFormValues((prev: any) => ({ ...prev, [id]: value }));
        }
    };

    const handleNewKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewKey(e.target.value);
    };

    const handleAddDetail = () => {
        if (newKey.trim() === '') {
            showToast('Maßnahmennummer darf nicht leer sein', true);
            return;
        }
        if (formValues.details[newKey]) {
            showToast('Maßnahmennummer existiert bereits', true);
            return;
        }
        setFormValues(prev => ({
            ...prev,
            details: {
                ...prev.details,
                [newKey]: { designation: '', startDate: '', endDate: '' }
            }
        }));
        setNewKey('');
    };

    const handleRemoveDetail = (key: string) => {
        setFormValues(prev => {
            const updated = { ...prev.details };
            delete updated[key];
            return { ...prev, details: updated };
        });
    };

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        disabled = false,
        marginBottom = 3
    ) => (
        <Form.Group className={`mb-${marginBottom} w-100`} controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            {type === 'textarea' ? (
                <Form.Control
                    as="textarea"
                    rows={5}
                    type={type}
                    value={String(formValues[id] ?? '')}
                    onChange={handleInputChange}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            ) : (
                <Form.Control
                    type={type}
                    value={type !== 'file' ? String(formValues[id] ?? '') : ''}
                    onChange={handleInputChange}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
            )}
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );

    const createMeasureFormGroup = (
        key: string,
        label: string,
        field: keyof IGlobalMeasureDetails[string],
        value: string,
        type = 'text',
        required = false,
        disabled = false
    ) => (
        <Form.Group className="mb-3 w-100" controlId={`details.${key}.${field}`}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                type={type}
                value={value}
                onChange={handleInputChange}
                required={required}
                className='text-black bg-grey'
                disabled={disabled}
            />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );
    const createNewKeyFormGroup = (
        id: string,
        label: string,
        value: string,
        onChange: (e: ChangeEvent<HTMLInputElement>) => void,
        required = false,
        disabled = false
    ) => (
        <Form.Group className="w-100" controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            <InputGroup>
                <Form.Control
                    type="text"
                    value={value}
                    onChange={onChange}
                    required={required}
                    className='text-black bg-grey'
                    disabled={disabled}
                />
                <Button variant="primary rounded" onClick={handleAddDetail} className="ms-3">Maßnahmennummer hinzufügen</Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        </Form.Group>
    );


    return (
        <>
            <Button disabled={isDisabled} variant="primary" onClick={handleShow}>
                {buttonName}
            </Button>

            <Modal
                centered
                size="xl"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen='xl-down'
            >
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={handleClose}>
                                    <FontAwesomeIcon width={30} icon={faXmark} />Schließen
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>
                            <Row>
                                <Col>
                                    {createFormGroup('title', 'Titel', 'text', true, true)}
                                </Col>
                                <Col>
                                    {createFormGroup('description', 'Beschreibung', 'textarea', false)}
                                </Col>
                            </Row>

                            <div className="horizontal-line my-3"></div>

                            <Row>
                                <Col>
                                    <h5>Maßnahmendetails</h5>
                                    <Row className="mb-4">
                                        {Object.keys(formValues.details).map((key, index) => (
                                            <Col className='p-3' md={6} key={`detail-${index}`}>
                                                <Form.Group className="mb-3 w-100" controlId={`details.${key}.key`}>
                                                    <Form.Label>Maßnahmennummer</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="text"
                                                            value={key}
                                                            required
                                                            disabled
                                                            className='text-black bg-grey rounded'
                                                        />
                                                        <Button className='rounded ms-3' variant="danger" onClick={() => handleRemoveDetail(key)}>Entfernen</Button>
                                                    </InputGroup>
                                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                                </Form.Group>
                                                {createMeasureFormGroup(key, 'Bezeichnung', 'designation', formValues.details[key].designation)}
                                                {createMeasureFormGroup(key, 'Startdatum', 'startDate', formValues.details[key].startDate, 'date')}
                                                {createMeasureFormGroup(key, 'Enddatum', 'endDate', formValues.details[key].endDate, 'date')}
                                            </Col>
                                        ))}
                                    </Row>

                                    <div className="horizontal-line my-3"></div>
                                    
                                    <Row>
                                        <Col className='px-3' md={6}>
                                            {createNewKeyFormGroup('newKey', 'Neue Maßnahmennummer', newKey, handleNewKeyChange)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default GlobalMeasureDetailsModal;
