import { Fragment, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PermissionsEnum,
} from '../../utils/enum';
import { ApiClient } from '../../services/ApiClient';
import { Participant } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';
import ParticipantDetailsHeader from '../../components/participants/detail/ParticipantDetailsHeader';
import ParticipantDetails from '../../components/participants/detail/ParticipantDetails';
import ParticipantImportantInfos from '../../components/participants/detail/ParticipantImportantInfos';
import ComboButtonGroup, { ComboButtonId } from '../../components/ComboButtonGroup';
import ParticipantActivities from '../../components/participants/detail/ParticipantActivities';
import DealInfos from '../../components/participants/detail/DealInfos';
import { getDealLabel } from '../../utils/utils';
import ParticipantAttendances from '../../components/participants/detail/ParticipantAttendances';

interface DealComboButton {
  id: string;
  label: string;
  type?: string;
}

function ParticipantDetail() {
  const { participantId } = useParams();
  const [participant, setParticipant] = useState<Participant>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('activities');
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const navigate = useNavigate();
  const { show, message, error, showToast, hideToast } = useToast();
  const [dealData, setDealData] = useState<any>(null);
  const [activitiesData, setActivitiesData] = useState<any>(null);
  const [attendancesData, setAttendancesData] = useState<any>(null);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'read');

      if (hasPermission) {
        participantId && fetchParticipant(participantId);
      } else {
        navigate('/errors/error404');
      }
    }
  }, [participantId, permissionsLoaded]);

  const fetchParticipant = async (participantId: string) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/participants/${participantId}`);
      const participantResponse = response.data;

      const response2 = await ApiClient.get(`/deals?participants_id=${participantId}`);
      const dealsResponse = response2.data;
      setParticipant({ ...participantResponse, deals: dealsResponse.list });
      setIsLoading(false);
    } catch (error) { }
  };

  const handleModalSubmit = () => {
    setParticipant(undefined);
    participantId && fetchParticipant(participantId);
  };

  const handleSelectedCombo = (newCombo: ComboButtonId) => {
    setSelectedCombo(newCombo);
  };

  const comboButtons: DealComboButton[] = useMemo(() => {
    const sortedDeals = participant?.deals.sort((a, b) => b.deals_id - a.deals_id) || [];

    return [
      { id: 'activities', label: 'Aktivitäten' },
      { id: 'attendances', label: 'Anwesenheiten' },
      ...sortedDeals.map((deal) => {
        const isAbort = Number(deal.status) === 0;
        const isSuccess = Number(deal.status) === 1;
        const isNew = Number(deal.status) === 3;
        const isCurrentDeal = Number(deal.status) === 2;

        const label = getDealLabel(deal.start, isCurrentDeal);
        const button: DealComboButton = {
          id: `${deal.deals_id}`,
          label,
          type: isAbort ? 'abort' : isSuccess ? 'success' : isNew ? 'warning' : isCurrentDeal ? 'info' : undefined
        };
        return button;
      })
    ];
  }, [participant]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedCombo === 'activities') {
          // const response = await ApiClient.get(`/activities?participant_id=${participantId}`);
          // setActivitiesData(response.data);
        } else if (selectedCombo === 'attendances') {
          // const response = await ApiClient.get(`/attendances?participant_id=${participantId}`);
          // setAttendancesData(response.data);
        } else {
          const response = await ApiClient.get(`/deals/${selectedCombo}`);
          setDealData(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedCombo, participantId]);

  const selectedDealInfo = useMemo(() => {
    if (!dealData) return null;

    const isAbort = Number(dealData.status) === 0;
    const isSuccess = Number(dealData.status) === 1;
    const isNew = Number(dealData.status) === 2;
    const isCurrentDeal = Number(dealData.status) === 2;

    return { selectedDeal: dealData, isCurrentDeal, isAbort, isSuccess, isNew };
  }, [dealData]);

  return (
    <Fragment>
      <Row>
        <Col >
          <ParticipantDetailsHeader participant={participant} isLoading={isLoading} onSubmitSuccess={handleModalSubmit}></ParticipantDetailsHeader>
        </Col>
      </Row>
      <Row>
        <Col lg={3}>
          <ParticipantDetails participant={participant} isLoading={isLoading}></ParticipantDetails>
          <ParticipantImportantInfos participant={participant} isLoading={isLoading}></ParticipantImportantInfos>
        </Col>
        <Col lg={9}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <ComboButtonGroup
                    buttons={comboButtons}
                    selectedCombo={selectedCombo}
                    setSelectedCombo={handleSelectedCombo}
                    borderRadius="normal"
                  ></ComboButtonGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {selectedCombo === 'activities' && <ParticipantActivities participant={participant} activities={activitiesData}></ParticipantActivities>}
              {selectedCombo === 'attendances' && <ParticipantAttendances participant={participant} attendances={attendancesData}></ParticipantAttendances>}
              {!isNaN(Number(selectedCombo)) && selectedDealInfo?.selectedDeal && <DealInfos deal={selectedDealInfo.selectedDeal} participant={participant} label={getDealLabel(selectedDealInfo.selectedDeal.start, selectedDealInfo.isCurrentDeal)} onSubmitSuccess={handleModalSubmit}></DealInfos>}
            </Col>
          </Row>

        </Col>
      </Row>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
}

export default ParticipantDetail;
