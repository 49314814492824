import React from 'react';
import { Deal, Participant, } from '../../../interfaces';

import Card from '../../bootstrap/card';
import { Col, Row } from 'react-bootstrap';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import StatusDisplay from '../StatusDisplay';
import { formatDateWithoutTime, getInitials } from '../../../utils/utils';
import Skeleton from 'react-loading-skeleton';
import AddEditParticipantModal from '../modal/AddEditParticipantModal';

interface ParticipantDetailsHeaderProps {
    isLoading?: boolean;
    participant?: Participant
    onSubmitSuccess: (participantId: number) => void;
}

const ParticipantDetailsHeader: React.FC<ParticipantDetailsHeaderProps> = ({
    isLoading,
    participant,
    onSubmitSuccess
}) => {

    const currentDeal = participant?.deals?.reduce((maxDeal, deal) => {
        return deal.deals_id > (maxDeal?.deals_id || 0) ? deal : maxDeal;
    }, null as Deal | null);

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col >
                        <div className="d-flex justify-content-between align-items-start align-items-lg-center flex-wrap mb-3">
                            <div className='d-flex justify-content-center align-items-center'>
                                <ParticipantInitialsComponent size={40} initials={getInitials(participant?.title ?? '')} ></ParticipantInitialsComponent>
                                <h4 className='mb-0 ms-3'>{isLoading ? <Skeleton width={150} /> : (participant?.title || '-')}</h4>
                            </div>
                            {participant && <AddEditParticipantModal
                                participant={participant}
                                modalTitle="Teilnehmer bearbeiten"
                                buttonName={
                                    <>
                                        <span className="d-none d-lg-inline">Teilnehmer bearbeiten</span>
                                        <span className="d-inline d-lg-none">Bearbeiten</span>
                                    </>
                                }
                                onSubmitSuccess={onSubmitSuccess}
                            ></AddEditParticipantModal>}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {participant && <StatusDisplay
                            bgsStatus={2}
                            wvStatus={1}
                            timeModel={currentDeal?.timeModel || ''}
                            startDate={currentDeal ? formatDateWithoutTime(currentDeal.start) : ''}
                            endDate={currentDeal ? formatDateWithoutTime(currentDeal.end) : ''}
                            device={currentDeal?.macbook === 1 ? "MacBook erhalten" : ''}
                            studyStatus={participant?.studyStatus}
                        />}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ParticipantDetailsHeader;
