import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, } from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';

import { useSortableData } from '../../hooks/useSortableData';

import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Setting } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../hooks/usePermissions';


const Locations = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [settings, setSettings] = useState<Setting[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedSettings, requestSort } = useSortableData(settings);


  const fetchSettings = useCallback(async () => {
    setSettings([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    try {
      const response = await ApiClient.get(`/settings${queryParams}`);
      const settingResponse = response.data
      const parsedSettings: Setting[] = settingResponse.list.map((item: { details: string; }) => ({
        ...item,
        details: item.details ? JSON.parse(item.details) : null
      })) ?? [];

      setTotalPages(settingResponse.amountPages);
      setSettings(parsedSettings);
      setCurrentPage(settingResponse.page);
      setPageSize(settingResponse.itemsPerPage);
      setTotalEntries(settingResponse.amountAllItems);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.ViewSettings, 'read');

      if (hasPermission) {
        fetchSettings();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    fetchSettings,
    permissionsLoaded,
    location,
  ]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Settings</h3>
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('ident')}
              >
                Title
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('ident')}
              >
                Ident / Kürzel
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedSettings.map((setting) => (
                <tr key={setting.settings_id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Link
                      to={`/${companyId}/settings/${setting.settings_id}`}
                      className="btn btn-link ps-0"
                    >
                      {setting.title}
                    </Link>
                  </td>

                  <td>
                    {setting.ident}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedSettings.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Settings gefunden</p>
          </div>
        )}
      </div>

      {sortedSettings.length > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              pageSize={pageSize}
              totalEntries={totalEntries}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Locations;
