import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface DropdownPortalMenuProps {
  children: ReactNode;
}

const DropdownPortalMenu: React.FC<DropdownPortalMenuProps> = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.body
  );
};

export default DropdownPortalMenu;