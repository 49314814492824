import { Participant } from '../../../interfaces';
import Card from '../../bootstrap/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';

interface ParticipantDetailsProps {
    isLoading?: boolean;
    participant?: Participant | null;
}

const ParticipantDetails: React.FC<ParticipantDetailsProps> = ({ isLoading = false, participant }) => {
    const address = participant?.street && participant?.zipCode && participant?.city ? `${participant.street}, ${participant.zipCode} ${participant.city}` : '-';

    const fields = [
        { icon: faCircleUser, label: 'Name', value: participant?.title },
        { icon: faEnvelope, label: 'E-Mail', value: participant?.email },
        ...(participant?.emailScnd ? [{ icon: faEnvelope, label: 'E-Mail 2', value: participant?.emailScnd }] : []),
        { icon: faPhone, label: 'Telefon', value: participant?.phoneNumber },
        { icon: faMapMarkerAlt, label: 'Adresse', value: address },
        { icon: faHubspot, label: 'Hubspot ID', value: participant?.hubspotId },
    ];

    return (
        <Card>
            <Card.Body>
                <h4>Details</h4>
                <hr />
                {fields.map((field, index) => (
                    <Row key={index} className="mb-2">
                        <Col lg={5}>
                            <FontAwesomeIcon width={15} icon={field.icon} className="me-2 text-muted" />
                            {field.label}
                        </Col>
                        <Col className='text-black'>
                            {isLoading ? <Skeleton width={150} /> : (field.value || '-')}
                        </Col>
                    </Row>
                ))}
            </Card.Body>
        </Card>
    );
};

export default ParticipantDetails;