import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Form,
    Dropdown,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Product } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { SelectOption } from '../../form/SelectWithSearch';
import { PermissionsEnum, ProductTypesEnum, } from '../../../utils/enum';
import { usePermissions } from '../../../hooks/usePermissions';
import DeleteModal from '../../DeleteModal';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import useCompanyNavigate from '../../../hooks/useCompanyNavigate';

type AddEditProductModalProps = {
    modalTitle: string;
    buttonName: string;
    product?: Product;
    onSubmitSuccess: (productId: number) => void;
};

interface FormValues {
    title: string
    type: string
    status: string
    lwId: string
    lwLink: string
    durationUes: number
    durationDays: number
    durationWeeks: number
    newLwSlug: string;
    newLwId: string;
    newLwLink: string;
    slug: string
}

const AddEditCertificateModal: React.FC<AddEditProductModalProps> = ({
    modalTitle,
    buttonName,
    product,
    onSubmitSuccess,
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const { productId } = useParams<{ productId?: string }>();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const { userHasPermissionByRight } = usePermissions();
    const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
    const companyNavigate = useCompanyNavigate();
    const [formValues, setFormValues] = useState({
        name: product?.name ?? '',
        title: product?.title ?? '',
        type: product?.type ?? '',
        status: product?.status ?? '',
        slug: product?.slug ?? '',
        lwId: product?.lwId ?? '',
        lwLink: product?.lwLink ?? '',
        newLwSlug: product?.newLwSlug ?? '',
        newLwId: product?.newLwId ?? '',
        newLwLink: product?.newLwLink ?? '',
        durationUes: product?.durationUes ?? 0,
        durationDays: product?.durationDays ?? 0,
        durationWeeks: product?.durationWeeks ?? 0,
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>({
        ...formValues,
    });

    useEffect(() => {
        if (!showModal) {
            setFormValues(initialFormValues as any);
        }
    }, [showModal]);

    const checkIfDataChanged = (): boolean => {
        return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                let response;

                if (productId) {
                    response = await ApiClient.put(`/products/${productId}`, formValues);
                } else {
                    response = await ApiClient.post('/products', formValues);
                }
                const id = response.data.products_id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                handleClose();
                setValidated(false);
            }
        }
    };


    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        const { id, value } = e.target;
        setFormValues((prev: any) => ({ ...prev, [id]: value }));
    };

    const createFormGroup = (
        id: keyof FormValues,
        label: string,
        type = 'text',
        required = false,
        infoLabel: string = '',
    ) => {

        const formatDate = (dateStr: string = "") => {
            if (type === 'date') {
                return dateStr.split(' ')[0];
            }
            return dateStr;
        };

        return (
            <Form.Group className="mb-3 w-100" controlId={id.toString()}>
                <Form.Label style={{ color: 'black' }}>{label} {infoLabel && <small>{infoLabel}</small>}</Form.Label>
                <Form.Control
                    style={{ backgroundColor: '#F9F9F9', color: 'black' }}
                    type={type}
                    value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]) : ''}
                    onChange={handleInputChange}
                    required={required}
                    isInvalid={validated && !formValues[id]}
                />
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            </Form.Group>
        );
    };

    const createSelectGroup = (
        id: keyof FormValues,
        label: string,
        options: SelectOption[],
        placeholder?: string,
        required = false
    ) => (
        <Form.Group
            style={{ color: 'black' }}
            className="mb-3 w-100"
            controlId={id}
        >
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Select
                value={formValues[id]}
                onChange={handleInputChange}
                required={required}
                style={{ backgroundColor: '#F9F9F9', color: 'black' }}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );

    const handleDeleteItem = (message: string, isError: boolean) => {
        showToast(message, isError);
        handleClose();
        companyNavigate('/participants');
    };


    return (
        <>
            <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewProducts, 'write')} variant="primary" onClick={handleShow}>
                {buttonName}
            </Button>

            <Modal
                centered
                size="xl"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen='xl-down'
            >
                {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
                <Modal.Header>
                    <div>
                        <Modal.Title>
                            {' '}
                            <h4>{modalTitle}</h4>
                        </Modal.Title>
                        {!checkIfDataChanged() ? (
                            <span>Keine Änderungen</span>
                        ) : (
                            <span className="text-danger">Änderungen</span>
                        )}
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className="btn-soft-primary dropdown-no-arrow round-button"
                                style={{ padding: 0 }}
                            >
                                <span className="visually-hidden">More options</span>
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={handleClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>
                                {product && <DeleteModal
                                    modalTitle="Löschen?"
                                    buttonName="Löschen"
                                    entityIds={[product?.products_id!]}
                                    entityType='products'
                                    onSubmit={handleDeleteItem}
                                    onDeleteModalChange={setIsTopLevelModalOpen}
                                    isDropdownItem={true}
                                    isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewProducts, 'delete')}>
                                </DeleteModal>}

                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            disabled={isLoading}
                            variant="primary"
                            onClick={() => hiddenSubmitButtonRef.current?.click()}
                        >
                            Fertig
                            {isLoading && (
                                <Spinner
                                    className="ms-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                ></Spinner>
                            )}
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <>
                            <Row>
                                <Col>
                                    <h6>Allgemein</h6>
                                </Col>
                                <Col>
                                    {createFormGroup('title', 'Titel')}
                                    {createFormGroup('lwId', 'LW ID')}
                                    {createSelectGroup(
                                        'type',
                                        "Typ",
                                        Object.entries(ProductTypesEnum).map(([value, label]) => ({
                                            value,
                                            label,
                                        })),
                                        'Auswählen...',
                                    )}
                                    {createFormGroup('newLwId', 'Lernwelt - ID (neue LW)')}
                                    {createFormGroup('newLwSlug', 'Kürzel (neue LW)')}
                                </Col>
                                <Col>
                                    {createFormGroup('status', 'Status')}
                                    {createFormGroup('lwLink', 'LW Link')}
                                    {createFormGroup('slug', 'Kürzel')}
                                    {createFormGroup('newLwLink', 'Lernwelt - Link (neue LW)')}
                                </Col>
                            </Row>

                            <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

                            <Row>
                                <Col>
                                    <h6>Zeiten</h6>
                                </Col>
                                <Col>
                                    {createFormGroup('durationUes', "Dauer in Ue's ")}
                                    {createFormGroup('durationWeeks', 'Dauer in Wochen')}
                                </Col>
                                <Col>
                                    {createFormGroup('durationDays', 'Dauer in Tagen')}

                                </Col>
                            </Row>
                        </>
                        <Button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={hiddenSubmitButtonRef}
                        ></Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast
                show={show}
                onClose={hideToast}
                message={message}
                error={error}
            />
        </>
    );
};

export default AddEditCertificateModal;
