import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faBan, faFile, faFileAlt, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { BgsStatus, BgsStatusEnum } from '../utils/enum';

interface BgsStatusIndicatorProps {
    bgsStatus: BgsStatus;
}

const bgsStatusIconMap: { [key: number]: IconDefinition } = {
    0: faBan,
    1: faFile,
    2: faFileAlt,
    3: faPaperPlane,
};

const bgsStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-success',
};

const BgsStatusIndicator: React.FC<BgsStatusIndicatorProps> = ({ bgsStatus }) => {
    const bgsStatusIcon = bgsStatusIconMap[Number(bgsStatus)];
    const bgsStatusColor = bgsStatusColorMap[Number(bgsStatus)];
    const bgsStatusText = BgsStatusEnum[Number(bgsStatus) as BgsStatus];

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${bgsStatusColor}`}>
            <FontAwesomeIcon icon={bgsStatusIcon} className="me-2" />
            <span>{bgsStatusText}</span>
        </div>
    );
};

export default BgsStatusIndicator;
