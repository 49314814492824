import React from 'react';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DealStatus, DealStatusEnum, getEnumValue } from '../utils/enum';
import { faBan, faCheck, faSpinner, faUserPlus } from '@fortawesome/pro-regular-svg-icons';

interface DealBadgeProps {
  status: DealStatus
}

const DealBadge: React.FC<DealBadgeProps> = ({ status }) => {
  if (Number(status) === 0) {
    return (
      <div>
        <Badge className="ms-2 d-flex align-items-center p-2 custom-danger-badge">
          <FontAwesomeIcon icon={faBan} className="me-1" />
          {getEnumValue(DealStatusEnum, status.toString())}
        </Badge>
      </div>
    );
  }
  if (Number(status) === 3) {

    return (
      <div>
        <Badge className="ms-2 d-flex align-items-center p-2 custom-primary-badge">
          <FontAwesomeIcon icon={faUserPlus} className="me-1" />
          {getEnumValue(DealStatusEnum, status.toString())}
        </Badge>
      </div>
    );

  }
  if (Number(status) === 2) {
    return (
      <div>
        <Badge bg="info" className="ms-2 d-flex align-items-center p-2">
          <FontAwesomeIcon icon={faSpinner} className="me-1" />
          {getEnumValue(DealStatusEnum, status.toString())}
        </Badge>
      </div>
    );
  }

  if (Number(status) === 1) {
    return (
      <div>
        <Badge className="ms-2 d-flex align-items-center p-2 custom-success-badge">
          <FontAwesomeIcon icon={faCheck} className="me-1" />
          {getEnumValue(DealStatusEnum, status.toString())}
        </Badge>
      </div>
    );
  }

  return null;
};

export default DealBadge;
