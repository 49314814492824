import React from 'react';
import { Button, Card, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Participant } from '../../../interfaces';
import ParticipantInitialsComponent from '../../ParticipantInitialsComponent';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';

interface ParticipantActivitiesProps {
    participant?: Participant | null;
    activities?: any
}

const ParticipantActivities: React.FC<ParticipantActivitiesProps> = ({ participant, activities }) => {
    const activitiesData: any[] = [
        {
            date: '30. November 2023',
            entries: [
                {
                    name: 'Erika Musterfrau',
                    duration: '03:44',
                    time: '15:01 Uhr',
                },
                {
                    name: 'Erika Musterfrau',
                    duration: '02:30',
                    time: '10:22 Uhr',
                },
            ],
        },
        {
            date: '22. September 2023',
            entries: [
                {
                    name: 'Erika Musterfrau',
                    duration: '03:44',
                    time: '15:01 Uhr',
                },
            ],
        },
    ];

    return (
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                        <h4>Aktivitäten</h4>
                    </div>
                    <Button className='btn btn-soft-primary'>Notiz hinzufügen <FontAwesomeIcon className='ms-1' icon={faPenToSquare} /></Button>
                </div>
                <hr />
                {activitiesData.map((activity: any, index: any) => (
                    <div key={index}>
                        <h6>{activity.date}</h6>
                        {activity.entries.map((entry: any, subIndex: any) => (
                            <div className="d-flex align-items-start mb-3" key={subIndex}>
                                <div className="me-3">
                                    <ParticipantInitialsComponent initials="EM" size={40} />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <p className="mb-2 text-muted">Telefonat mit <strong>{entry.name}</strong></p>
                                            <p className="mb-2 text-muted">Gesprächsdauer: {entry.duration}</p>
                                        </div>
                                        <div className="text-muted d-flex align-items-center">
                                            {entry.time}
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ms-2" />
                                        </div>
                                    </div>
                                    <FormControl
                                        type='input'
                                        placeholder="Notiz hinzufügen"
                                        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
                {activitiesData.length === 0 && (
                    <div
                        className="d-flex justify-content-center align-items-center border rounded my-3"
                        style={{ height: '50px' }}
                    >
                        <p className="p-0 m-0">Keine Aktivitäten gefunden</p>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default ParticipantActivities;
