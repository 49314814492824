import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faBan, faFileContract, faEnvelopeOpen,faFileSignature, faPaperPlane, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { WvStatus, WvStatusEnum } from '../utils/enum';

interface WvStatusIndicatorProps {
    wvStatus: WvStatus;
}

const wvStatusIconMap: { [key: number]: IconDefinition } = {
    0: faTimesCircle,
    1: faFileContract,
    2: faPaperPlane,
    3: faEnvelopeOpen,
    4: faBan, 
    5: faFileSignature,
    6: faPaperPlane,
};

const wvStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-warning',
    4: 'text-danger',
    5: 'text-success',
    6: 'text-success',
};

const WvStatusIndicator: React.FC<WvStatusIndicatorProps> = ({ wvStatus }) => {
    const wvStatusIcon = wvStatusIconMap[Number(wvStatus)];
    const wvStatusColor = wvStatusColorMap[Number(wvStatus)];
    const wvStatusText = WvStatusEnum[Number(wvStatus) as WvStatus];

    return (
        <div className={`d-flex align-items-center me-md-4 mb-2 mb-md-0 ${wvStatusColor}`}>
            <FontAwesomeIcon icon={wvStatusIcon} className="me-2" />
            <span>{wvStatusText}</span>
        </div>
    );
};

export default WvStatusIndicator;
