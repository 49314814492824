import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Participant } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { SelectOption } from '../../form/SelectWithSearch';
import { PermissionsEnum, SalutationEnum, StudyStatusEnum, TitleOptions, getEnumValue, participantLabels } from '../../../utils/enum';
import { usePermissions } from '../../../hooks/usePermissions';
import DeleteModal from '../../DeleteModal';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import useCompanyNavigate from '../../../hooks/useCompanyNavigate';
import ArchiveModal from '../../ArchiveModal';

type AddEditParticipantProps = {
  modalTitle: string;
  buttonName: string | React.ReactNode;
  participant?: Participant;
  onSubmitSuccess: (participantId: number) => void;
};

interface FormValues {
  city: string;
  discordId: string;
  email: string;
  emailScnd: string;
  firstname: string;
  hubspotId: string,
  lastname: string;
  phoneNumber: string;
  salutation?: number;
  secondName: string;
  street: string;
  zipCode: string;
  theTitle: string
  studyStatus: number
}

const AddEditParticipantModal: React.FC<
  AddEditParticipantProps
> = ({ modalTitle, buttonName, participant, onSubmitSuccess }) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const { participantId } = useParams<{ participantId?: string }>();
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const companyNavigate = useCompanyNavigate();
  const { userHasPermissionByRight } = usePermissions();

  const [formValues, setFormValues] = useState({
    name: participant?.name ?? '',
    city: participant?.city ?? '',
    discordId: participant?.discordId ?? '',
    email: participant?.email ?? '',
    emailScnd: participant?.emailScnd ?? '',
    firstname: participant?.firstname ?? '',
    media_id: participant?.media_id ?? 0,
    hubspotId: participant?.hubspotId ?? '',
    lastname: participant?.lastname ?? '',
    lwId: participant?.lwId ?? '',
    notice: participant?.notice ?? '',
    phoneNumber: participant?.phoneNumber ?? '',
    salutation: participant?.salutation,
    secondName: participant?.secondName ?? '',
    studyStatus: participant?.studyStatus ?? 1,
    street: participant?.street ?? '',
    theTitle: participant?.theTitle ?? '',
    title: participant?.title ?? '',
    zipCode: participant?.zipCode ?? '',
    media: participant?.media ?? null
  });

  useEffect(() => {
    if (!showModal) {
        setFormValues(initialFormValues as any);
    }
}, [showModal]);

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;
        if (participantId) {
          response = await ApiClient.put(`/participants/${participantId}`, formValues);
        } else {
          response = await ApiClient.post('/participants', formValues);
        }
        const participantsId = response.data.participants_id;
        onSubmitSuccess(participantsId);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        handleClose();
        setValidated(false);
      }
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [id]: value }));
  };


  const handleSelectChange = (
    id: string,
    selectedOption: { value: string; label: string } | null
  ) => {
    setFormValues((prev: any) => ({
      ...prev,
      [id]: selectedOption ? selectedOption.value : '',
    }));
  };

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false
  ) => {
    const commonProps = {
      value: type !== 'file' ? formValues[id] : '',
      onChange: handleInputChange,
      required: required,
      isInvalid: validated && !formValues[id],
    };

    return (
      <Form.Group className="mb-3 w-100" controlId={String(id)}>
        <Form.Label style={{ color: 'black' }}>{label}</Form.Label>
        {type === 'textarea' ? (
          <Form.Control
            as="textarea"
            {...commonProps}
            style={{
              backgroundColor: '#F9F9F9',
              color: 'black',
              height: '150px',
            }}
          />
        ) : (
          <Form.Control
            type={type}
            {...commonProps}
            style={{ backgroundColor: '#F9F9F9', color: 'black' }}
          />
        )}
        <Form.Control.Feedback type="invalid" />
      </Form.Group>
    );
  };

  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false,
    disabled: boolean = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        disabled={disabled}
        value={formValues[id]}
        onChange={handleInputChange}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError);
    handleClose();
    companyNavigate('/participants');
  };

  return (
    <>
      <Button disabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'write')} variant="primary" onClick={handleShow}>
        {buttonName}
      </Button>

      <Modal
        centered
        size="xl"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen='xl-down'
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={handleClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>

                {participant && participant?.status !== 3 && <ArchiveModal
                  modalTitle="Auswahl archivieren?"
                  buttonName="Archivieren"
                  entityIds={[participant?.participants_id!]}
                  entityType='participants'
                  onSubmit={() => onSubmitSuccess(participant?.participants_id!)}
                  onArchiveModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'delete')}>
                </ArchiveModal>}

                {participant && participant?.status === 3 && <DeleteModal
                  modalTitle="Löschen?"
                  buttonName="Löschen"
                  entityIds={[participant?.participants_id!]}
                  entityType='participants'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.ViewParticipants, 'delete')}>
                </DeleteModal>}

              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Row>
              <Col lg={4}>
                <h6>Personendaten</h6>
              </Col>
              <Col>
                {createSelectGroup(
                  'salutation',
                  getEnumValue(participantLabels, 'salutation'),
                  Object.entries(SalutationEnum).map(([value, label]) => ({
                    value,
                    label,
                  })),
                  'Auswählen...',
                  true
                )}
                {createFormGroup('firstname', getEnumValue(participantLabels, 'firstname'), 'text', true)}
                {createFormGroup('lastname', getEnumValue(participantLabels, 'lastname'), 'text', true)}
              </Col>
              <Col>
                {createSelectGroup(
                  'theTitle',
                  getEnumValue(participantLabels, 'theTitle'),
                  Object.entries(TitleOptions).map(([value, label]) => ({
                    value,
                    label,
                  })),
                  'Auswählen...',
                )}
                {createFormGroup('secondName', getEnumValue(participantLabels, 'secondName'))}
                {createSelectGroup(
                  'studyStatus',
                  getEnumValue(participantLabels, 'studyStatus'),
                  Object.entries(StudyStatusEnum).map(([value, label]) => ({
                    value,
                    label,
                  })),
                  'Auswählen...',
                  true
                )}
              </Col>
            </Row>

            <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

            <Row>
              <Col>
                <h6>Kontakt</h6>
              </Col>
              <Col>
                {createFormGroup('street', getEnumValue(participantLabels, 'street'))}
                {createFormGroup('city', getEnumValue(participantLabels, 'city'))}
                {createFormGroup('zipCode', getEnumValue(participantLabels, 'zipCode'))}
              </Col>
              <Col>
                {createFormGroup('email', getEnumValue(participantLabels, 'email'), 'email', true)}
                {createFormGroup('emailScnd', getEnumValue(participantLabels, 'emailScnd'))}
                {createFormGroup('phoneNumber', getEnumValue(participantLabels, 'phoneNumber'))}
              </Col>
            </Row>


            <div className="horizontal-line-negative-margin mt-3 mb-3"></div>

            <Row>
              <Col>
                <h6>Verknüpfungen</h6>
              </Col>
              <Col>
                {createFormGroup('discordId', getEnumValue(participantLabels, 'discordId'))}
              </Col>
              <Col>
                {createFormGroup('hubspotId', getEnumValue(participantLabels, 'hubspotId'))}
              </Col>
            </Row>


            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditParticipantModal;
