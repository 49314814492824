import Calendar from 'rc-year-calendar';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faChevronLeft, faChevronRight, faFileArrowDown } from '@fortawesome/pro-regular-svg-icons';
import ReactDOM from 'react-dom/client';
import Card from './bootstrap/card';
import { Button } from 'react-bootstrap';

Calendar.locales['de'] = {
  days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
  daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
  monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  weekShort: 'KW',
  weekStart: 1
};

interface Event {
  startDate: Date;
  endDate: Date;
  name: string;
  type: 'absence' | 'attendance' | 'dealPeriod';
}

const CalendarWrapper: React.FC = () => {
  const events: Event[] = [
    { startDate: new Date(2024, 1, 1), endDate: new Date(2024, 4, 5), name: 'Deal Period', type: 'dealPeriod' },
    { startDate: new Date(2024, 1, 1), endDate: new Date(2024, 1, 3), name: 'Absence', type: 'absence' },
    { startDate: new Date(2024, 2, 4), endDate: new Date(2024, 2, 10), name: 'Attendance', type: 'attendance' },
    { startDate: new Date(2024, 3, 8), endDate: new Date(2024, 3, 14), name: 'Absence', type: 'absence' },
  ];
  const [year, setYear] = useState(new Date().getFullYear());

  const customDayRenderer = (element: HTMLElement, currentDate: Date) => {
    const dayEvents = events.filter(event => {
      return (
        currentDate >= new Date(event.startDate) &&
        currentDate <= new Date(event.endDate)
      );
    });

    const specialEvent = dayEvents.find(event => event.type === 'dealPeriod');
    if (specialEvent) {
      element.classList.add('deal-period');
    }

    const topEvent = dayEvents.find(event => event.type === 'absence' || event.type === 'attendance');
    if (topEvent) {
      if (topEvent.type === 'absence') {
        element.classList.add('absence');
        element.classList.remove('deal-period');
      } else if (topEvent.type === 'attendance') {
        element.classList.add('attendance');
        element.classList.remove('deal-period');
      }
    }
  };

  useEffect(() => {
    const currentWeekNumber = (date: Date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date.valueOf() - firstDayOfYear.valueOf()) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };
  
    const today = new Date();
    const currentWeek = currentWeekNumber(today);
    const currentYear = today.getFullYear();
  
    const weeksWithEvents = new Set<string>();
  
    events.forEach(event => {
      const eventStartDate = new Date(event.startDate);
      const eventYear = eventStartDate.getFullYear();
      const eventWeek = currentWeekNumber(eventStartDate);
      weeksWithEvents.add(`${eventYear}-${eventWeek}`);
    });
  
    const weekNumberCells = document.querySelectorAll('.calendar .week-number');
    weekNumberCells.forEach(cell => {
      const weekNumber = parseInt(cell.textContent!.trim());
      const cellYear = parseInt(cell.closest('[data-year]')?.getAttribute('data-year') || currentYear.toString());
  
      if (isNaN(weekNumber)) return;
  
      cell.innerHTML = '';
  
      const container = document.createElement('div');
      container.className = 'week-number-container';
  
      const weekNumberSpan = document.createElement('div');
      weekNumberSpan.className = 'week-number-text';
      weekNumberSpan.textContent = weekNumber.toString().padStart(2, '0');
      container.appendChild(weekNumberSpan);
  
      const iconContainer = document.createElement('span');
      iconContainer.className = 'icon-column';
      const root = ReactDOM.createRoot(iconContainer);
  
      const weekKey = `${cellYear}-${weekNumber}`;
  
      if (weeksWithEvents.has(weekKey) && weekNumber <= currentWeek && cellYear === currentYear) {
        root.render(<div><FontAwesomeIcon style={{ color: '#8A92A6', visibility: 'visible' }} width={20} icon={faFileArrowDown} /></div>);
        container.style.pointerEvents = 'auto';
        (cell as HTMLElement).style.cursor = 'pointer';
      } else if (weekNumber <= currentWeek && cellYear === currentYear) {
        root.render(<div><FontAwesomeIcon style={{ color: '#8A92A6', visibility: 'visible' }} width={20} icon={faBell} /></div>);
        container.style.pointerEvents = 'auto';
        (cell as HTMLElement).style.cursor = 'pointer';
      } else {
        root.render(<div><FontAwesomeIcon style={{ color: '#8A92A6', visibility: 'hidden' }} width={20} icon={faBell} /></div>);
        container.style.pointerEvents = 'none';
      }
      container.appendChild(iconContainer);
      cell.appendChild(container);
  
      if (weekNumber === currentWeek && cellYear === year) {
        weekNumberSpan.classList.add('current-week');
      }
  
      cell.addEventListener('click', () => {
        if (container.style.pointerEvents !== 'none') {
          alert(`Week ${weekNumber} clicked!`);
        }
      });
    });
  }, [events]);
  

  const customHeader = (currentYear: number, onPrevYear: () => void, onNextYear: () => void) => {
    return (

      <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
        <h4>Anwesenheiten {currentYear}</h4>
        <div className='d-flex'>
          <Button onClick={onPrevYear} variant="secondary"
            id="dropdown-basic"
            className="btn-soft-primary round-button me-2"
            style={{ padding: 0 }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>

          <Button onClick={onNextYear} variant="secondary"
            id="dropdown-basic"
            className="btn-soft-primary round-button"
            style={{ padding: 0 }}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Card>
      <Card.Body>
        {customHeader(year, () => setYear(year - 1), () => setYear(year + 1))}
        <hr />
        <div className="calendar-container">
          <Calendar
            displayHeader={false}
            dataSource={events}
            displayWeekNumber={true}
            customDayRenderer={customDayRenderer}
            language='de'
            year={year}
          />
        </div>
      </Card.Body>
    </Card>


  );

};

export default CalendarWrapper;
