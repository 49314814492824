import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import {
  CompanyEnum,
  UserStatus,
  UserStatusEnum,
} from '../../../utils/enum';
import { User, UserRole } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { generateRandomHex } from '../../../utils/utils';

type AddEditUsersModalProps = {
  modalTitle: string;
  buttonName: string;
  user?: User;
  onSubmitSuccess: (locationsId: number) => void;
  isTableItem?: boolean
  userRoles: UserRole[]
};

interface SelectOption {
  value: string;
  label: string;
}

interface FormValues {
  accessToken: string,
  email: string,
  name: string,
  pass: string,
  roles_id: string,
  status: UserStatus
  title: string
  company: string
}

const AddEditUsersModal: React.FC<AddEditUsersModalProps> = ({
  modalTitle,
  buttonName,
  user,
  onSubmitSuccess,
  isTableItem,
  userRoles
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setValidated(false)
  };
  const handleShow = () => setShowModal(true);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const [formValues, setFormValues] = useState({
    accessToken: user?.accessToken ?? '',
    email: user?.email ?? '',
    name: user?.name ?? '',
    pass: '',
    roles_id: user?.roles_id.toString() ?? '',
    status: user?.status ?? 1,
    title: user?.title ?? '',
    company: user?.company ? JSON.parse(user?.company) : []
  });
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  useEffect(() => {
    if (!showModal) {
        setFormValues(initialFormValues as any);
    }
}, [showModal]);

  const [showPassword, setShowPassword] = useState(false);

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;
        const updatedFormValues = {
          ...formValues,
          company: JSON.stringify(formValues.company),
      };

        if (user) {
          response = await ApiClient.put(
            `/users/${user.users_id}`,
            updatedFormValues
          );
        } else {
          response = await ApiClient.post('/users', updatedFormValues);
        }
        const usersId = response.data.users_id;
        onSubmitSuccess(usersId);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        setValidated(false);
        const newForm: FormValues = {
          accessToken: '',
          email: '',
          name: '',
          pass: '',
          roles_id: '',
          status: 1,
          title: '',
          company: ''
        }
        setFormValues(newForm)
        setInitialFormValues(newForm)
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (formValues.roles_id === '3') {
      setFormValues((prev) => ({ ...prev, accessToken: generateRandomHex() }));
    } else {
      setFormValues((prev) => ({ ...prev, accessToken: '' }));
    }
  }, [formValues.roles_id]);


  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [id]: value }));
  };


  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormValues((prev) => {
        let updatedCompanies = [...prev.company];
        if (checked) {
            if (!updatedCompanies.includes(id)) {
                updatedCompanies.push(id);
            }
        } else {
            updatedCompanies = updatedCompanies.filter(moduleId => moduleId !== id);
        }
        return { ...prev, company: updatedCompanies };
    });
};

  const createFormGroup = (
    id: keyof FormValues,
    label: string,
    type = 'text',
    required = false,
    infoLabel: string = ''
  ) => {

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const formatDate = (dateStr: string = "") => {
      if (type === 'date') {
        return dateStr.split(' ')[0];
      }
      return dateStr;
    };

    return (
      <Form.Group className="mb-3 w-100 text-black" controlId={id.toString()}>
        <Form.Label className='text-black'>{label} {infoLabel && <small>{infoLabel}</small>}</Form.Label>
        {type !== 'password' ? (
          <Form.Control
            style={{ backgroundColor: '#F9F9F9' }}
            className='text-black'
            type={type}
            value={type !== 'file' ? (type === 'date' ? formatDate(formValues[id]?.toString()) : formValues[id]) : ''}
            onChange={handleInputChange}
            required={required}
            isInvalid={validated && !formValues[id]}
          />
        ) : (
          <InputGroup>
            <Form.Control
              style={{ backgroundColor: '#F9F9F9' }}
              className='text-black'
              type={showPassword ? 'text' : 'password'}
              value={formValues[id] || ''}
              onChange={handleInputChange}
              required={required}
              isInvalid={validated && !formValues[id]}
              autoComplete="new-password"
            />
            <Button
              variant=""
              onClick={toggleShowPassword}
              className="border-left-0"
              style={{ backgroundColor: '#F9F9F9', border: '1px solid #eee', }}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </Button>
          </InputGroup>
        )}
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>
    );
  };


  const createSelectGroup = (
    id: keyof FormValues,
    label: string,
    options: SelectOption[],
    placeholder?: string,
    required = false
  ) => (
    <Form.Group
      style={{ color: 'black' }}
      className="mb-3 w-100"
      controlId={id}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        value={formValues[id]}
        onChange={handleInputChange}
        required={required}
        style={{ backgroundColor: '#F9F9F9', color: 'black' }}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );

  const renderCheckboxes = (label: string) => {
    const companyKeys = Object.keys(CompanyEnum) as Array<keyof typeof CompanyEnum>;
  
    return (
      <Form.Group style={{ color: 'black' }} className="mb-3 w-100">
        {label && <Form.Label>{label}</Form.Label>}
        {companyKeys.map((company) => (
          <Form.Check
            key={company}
            type="checkbox"
            id={company}
            label={CompanyEnum[company]}
            checked={formValues.company.includes(company)}
            onChange={handleCheckboxChange}
          />
        ))}
      </Form.Group>
    );
  };

  return (
    <>
      {isTableItem ?
        <Button
          variant="link" onClick={handleShow}
        >
          {buttonName}
        </Button>
        :
        <Button variant="primary" onClick={handleShow}>
          {buttonName}
        </Button>
      }

      <Modal
        centered
        size="xl"
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen='xl-down'
      >
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button"
                style={{ padding: 0 }}
              >
                <span className="visually-hidden">More options</span>
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleClose}>Close</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <Row>
              <Col>
                <h6>Allgemein</h6>
              </Col>
              <Col>
                {createFormGroup('title', 'Titel', 'text', true)}
                {createSelectGroup(
                  'roles_id',
                  'Benutzergruppe',
                  userRoles.map(r => ({
                    value: r.roles_id,
                    label: r.title,
                  })),
                  'Auswählen...',
                  true
                )}
              </Col>
              <Col>
                {createSelectGroup(
                  'status',
                  'Status',
                  Object.entries(UserStatusEnum).map(([value, label]) => ({
                    value,
                    label,
                  })),
                  'Auswählen...',
                  true
                )}
                {renderCheckboxes("Unternehmen")}
              </Col>
            </Row>

            <div className="horizontal-line my-3"></div>

            <Row>
              <Col lg={4}>
                <h6>Anmeldedaten</h6>
              </Col>
              {formValues.roles_id === '3' ?
                <Col lg={4}>
                  {createFormGroup('accessToken', 'Accesstoken', 'password', true)}
                </Col> :
                <>
                  <Col>
                    {createFormGroup('email', 'E-Mail-Adresse', 'email', true)}
                  </Col>
                  <Col>
                    {createFormGroup('pass', 'Passwort', 'password', user ? false : true)}
                  </Col>
                </>
              }
            </Row>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body>
      </Modal>
      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditUsersModal;
