import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { WvStatus, WvStatusEnum } from '../utils/enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownPortalMenu from './DropdownPortalMenu';
import { faTimesCircle, faFileContract, faPaperPlane, faEnvelopeOpen, faBan, faFileSignature, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

interface WvStatusDropdownProps {
    statusWv: WvStatus;
    handleWvStatusUpdate: (status: WvStatus) => void;
    isDisabled?: boolean;
}

const wvStatusIconMap: { [key: number]: IconDefinition } = {
    0: faTimesCircle,
    1: faFileContract,
    2: faPaperPlane,
    3: faEnvelopeOpen,
    4: faBan, 
    5: faFileSignature,
    6: faPaperPlane,
};

const wvStatusColorMap: { [key: number]: string } = {
    0: 'text-danger',
    1: 'text-warning',
    2: 'text-warning',
    3: 'text-warning',
    4: 'text-danger',
    5: 'text-success',
    6: 'text-success',
};

const getIconByWVStatus = (status: WvStatus) => {
    return <FontAwesomeIcon icon={wvStatusIconMap[Number(status)]} className="me-2" />;
};

const getWvStatusColor = (status: WvStatus) => {
    return wvStatusColorMap[Number(status)];
};

const WvStatusDropdown: React.FC<WvStatusDropdownProps> = ({
    statusWv,
    handleWvStatusUpdate,
    isDisabled
}) => {
    return (
        <Dropdown>
            <Dropdown.Toggle
                disabled={isDisabled}
                variant="link"
                id="dropdown-certification-basic"
                className={`${getWvStatusColor(statusWv)} ps-0`}
            >
                <span className={getWvStatusColor(statusWv)}>
                    {getIconByWVStatus(statusWv)}
                    {WvStatusEnum[statusWv]}
                </span>
            </Dropdown.Toggle>
            <DropdownPortalMenu>
                <Dropdown.Menu>
                    {Object.entries(WvStatusEnum)
                        .filter(([statusKey, _]) => Number(statusKey) !== statusWv)
                        .map(([statusKey, statusValue]) => (
                            <Dropdown.Item
                                key={statusKey}
                                onClick={() => handleWvStatusUpdate(Number(statusKey) as WvStatus)}
                                className="d-flex align-items-center"
                            >
                                <FontAwesomeIcon
                                    width={15}
                                    icon={wvStatusIconMap[Number(statusKey)]}
                                    className={`me-2 ${getWvStatusColor(Number(statusKey) as WvStatus)}`}
                                />
                                {statusValue}
                            </Dropdown.Item>
                        ))}
                </Dropdown.Menu>
            </DropdownPortalMenu>
        </Dropdown>
    );
};

export default WvStatusDropdown;
