import React from 'react';
import { Participant } from '../../../interfaces';
import Calendar from '../../Calendar';

interface ParticipantAttendancesProps {
    participant?: Participant | null;
    attendances: any
}

const ParticipantAttendances: React.FC<ParticipantAttendancesProps> = ({ participant, attendances }) => {
    return (
        <Calendar></Calendar>
    );
};

export default ParticipantAttendances;
