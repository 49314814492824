import React, { useState, ChangeEvent, useEffect } from 'react';
import { InputGroup, FormControl, Button, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { getEnumValue, searchFilterTranslations } from '../utils/enum';

interface SearchInputProps {
  onSearch: (data: { query: string, filter: string | undefined }) => void;
  dropdownItems?: string[];
  reset?: boolean;
  hasFilters?: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({ onSearch, dropdownItems = [], reset, hasFilters }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchInputError, setSearchInputError] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [dropdownValue, setDropdownValue] = useState<string>();

  const handleSetSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (searchInputError) {
      setSearchInputError(false);
    }
  };

  const handleSearch = () => {
    if (!searchValue.trim()) {
      setSearchInputError(false);
      onSearch({ query: '', filter: dropdownValue });
    } else if (searchValue.length >= 3) {
      setSearchInputError(false);
      onSearch({ query: searchValue, filter: dropdownValue });
    } else {
      setSearchInputError(true);
      onSearch({ query: '', filter: dropdownValue });
    }
  };

  const handleSelectDropdown = (eventKey: string | null) => {
    if (eventKey) {
      setDropdownValue(eventKey);
      onSearch({ query: searchValue, filter: eventKey });
    }
  };

  useEffect(() => {
    if (!searchValue && !isFocused) {
      setSearchInputError(false);
      onSearch({ query: '', filter: dropdownValue });
    }
  }, [isFocused, searchValue, dropdownValue, onSearch]);

  useEffect(() => {
    if (reset) {
      setSearchValue('');
      setDropdownValue(undefined);
    }
  }, [reset]);

  return (
    <InputGroup className="search-input">
      {hasFilters && <DropdownButton
        title={dropdownValue ? getEnumValue(searchFilterTranslations, dropdownValue) : 'Filter'}
        onSelect={handleSelectDropdown}
      >
        <Dropdown.ItemText>
          <p className="mb-0" style={{ fontSize: '0.7rem' }}>
            FELD ZUM DURCHSUCHEN AUSWÄHLEN
          </p>
        </Dropdown.ItemText>
        <Dropdown.Item eventKey={'all'} key={'all'}>
          {getEnumValue(searchFilterTranslations, 'all')}
        </Dropdown.Item>
        {dropdownItems.map((item, index) => (
          <Dropdown.Item eventKey={item} key={index}>
            {getEnumValue(searchFilterTranslations, item)}
          </Dropdown.Item>
        ))}
      </DropdownButton>}


      <FormControl
        className='text-black custom-search-input'
        type="search"
        placeholder="Search..."
        value={searchValue}
        onChange={handleSetSearchValue}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
      <Button
        variant=""
        onClick={handleSearch}
        className="border-left-0 text-black bg-grey"
        style={{ border: '1px solid #eee' }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </Button>
      {searchInputError && (
        <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          Suchebegriff muss min. 3 Zeichen lang sein
        </Form.Control.Feedback>
      )}
    </InputGroup>
  );
};

export default SearchInput;
