import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { formatDateWithoutTime } from '../utils/utils';
import DealAbortModal from './deals/modal/DealAbortModal';
import { Deal } from '../interfaces';
import { ApiClient } from '../services/ApiClient';
import { DealStatus } from '../utils/enum';

interface DealManagementProps {
    status: DealStatus;
    cancelDate?: string;
    cancelReason?: string;
    deal: Deal
    onSubmitSuccess: (dealId: number) => void;
}

const DealManagement: React.FC<DealManagementProps> = ({
    status,
    cancelDate,
    cancelReason,
    deal,
    onSubmitSuccess
}) => {

    const handleResume = async () => {
        try {
            let response;
            const data = {
                abortDate: '0000-00-00 00:00:00',
                abortSended: '0000-00-00 00:00:00',
                abortReason: '',
                abort: 0,
                //abortNotice: ''
            }

            response = await ApiClient.put(`/deals/${deal?.deals_id}`, data);
            const dealsId = response.data.deals_id;
            onSubmitSuccess(dealsId);
        } catch (error) {
        } finally {
        }
    };

    return (
        <Card>
            <Card.Body>
                <h5 className="card-title">Deal verwalten</h5>
                {(Number(status) === 2 || Number(status) === 3) && (
                    <div className="border rounded border-danger p-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5>Deal abbrechen</h5>
                            <p>Der Deal kann nachträglich wieder fortgesetzt werden.</p>
                        </div>
                        <div>
                            <DealAbortModal modalTitle='Deal Abbrechen' buttonName='Deal Abbrechen' deal={deal} onSubmitSuccess={onSubmitSuccess}>
                            </DealAbortModal>
                        </div>
                    </div>
                )}
                {Number(status) === 0 && (
                    <div className="border rounded border-danger p-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5>Deal abgebrochen</h5>
                            <p>
                                Abgebrochen am {formatDateWithoutTime(cancelDate ?? '')} mit der Begründung: {cancelReason}
                            </p>
                        </div>

                        <Button variant="secondary" className='btn-soft-primary' onClick={handleResume}>
                            Deal fortsetzen
                        </Button>
                    </div>
                )} 
            </Card.Body>
        </Card>
    );
};

export default DealManagement;
